import React from "react";
import { useNavigate } from "react-router-dom";
import GenieCard from "../genie_card";

function MusicalEventGenie() {
  const genieData = [
    {
      id: 1,
      name: "Ruchi Seth",
      education_title: "Events",
      education_value: "Musical Events Planning Advisor",
      experience_title: "Current Job",
      experience_value: "Event Manager",
      photo: require("../../assets/genieImages/default_genie.jpeg"),
      designation: "Musical Events Planning Advisor",
      duration1: 30,
      oprice1: 599,
      dprice1: 249,
      duration2: 50,
      oprice2: 899,
      dprice2: 399,
      services: [
        "Event Theme and Concept: Assisting clients in selecting a music genre or theme for the event, whether it be classical, jazz, rock, or contemporary music.",
        "Budget Management: Providing advice on budget allocation for performers, equipment, venue, and other event-related costs.",
        "Venue Selection: Suggesting venues with appropriate acoustics and capacity for the type of musical performance and audience size.",
        "Artist Booking: Recommending and coordinating with musicians, bands, DJs, or solo performers based on the event’s theme and budget.",
        "Equipment and Sound System Management: Advising on the necessary audio equipment, sound systems, and technicians to ensure a high-quality sound experience.",
        "Lighting and Stage Design: Offering advice on stage setup, lighting design, and overall ambiance to enhance the musical experience.",
        "Event Timeline Planning: Developing a detailed schedule that includes sound checks, performances, intermissions, and other key moments.",
        "Logistics and Coordination: Ensuring smooth event operations by coordinating with the venue, artists, and staff on the day of the event.",
        "Guest Experience: Advising on ways to improve audience engagement, such as seating arrangements, interactive activities, or VIP experiences.",
        "Post-Event Feedback: Helping with the collection of attendee feedback and reviews for future event planning.",
      ],
    },
  ];
  const navigate = useNavigate();
  return (
    <section id="musical_event" className="block teams-block">
      <GenieCard title="Music Event Advisor" genieData={genieData} />
    </section>
  );
}

export default MusicalEventGenie;
