import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const servicesData = [
  {
    id: 1,
    icon: "fas fa-laptop-code",
    title: "Career Guidance",
    link: "/education?subcat=career",
    description:
      "Our genies offer personalized advice and resources for your career and help you explore your strengths and interests, simplify the complexities of the job market, maximize the career potential, and achieve long-term success in the chosen fields.",
  },
  {
    id: 2,
    icon: "fas fa-trophy",
    title: "Talk to IITian/MBBS",
    link: "/education?subcat=iit",
    description:
      "We understand that you feel curious about speaking with someone who has attended an institution as prestigious as the Indian Institutes of Technology (IITs) or govt. reputed medical colleges as they are renowned for their rigorous academic programs and talented students.That’s why we have our special IITian and MBBS genies to share their experience and address your queries and concerns about JEE/NEET preparation, college applications, research and placements etc."},
  {
    id: 3,
    icon: "fas fa-cannabis",
    title: "Nutritionist",
    link: "/health?subcat=nutritionist",
    description:
      "Our diet and nutrition genies play a vital role in empowering you to make informed decisions about your dietary choices, optimize your nutritional intake and improve your overall health and well-being through evidence-based nutrition guidance and support.",
  },
  {
    id: 4,
    icon: "fas fa-user-doctor",
    title: "Psychologist",
    link: "/health?subcat=pyschologist",
    description:
      "Our psychologist genies play a critical role in helping you understand and manage your thoughts, feelings and behaviors, as well as in promoting mental health, resilience, and personal growth across the lifespan. ",
  },
  {
    id: 5,
    icon: "fas fa-scale-balanced",
    title: "Legal Assistance",
    link: "/legal",
    description:
      "Our legal genies serve as a trusted navigator through the intricate waters of legal matters. They safeguard your rights and interests, guiding you towards your desired outcomes with astute legal counsel, unwavering advocacy and strategic representation. Whether it's untangling complex legal webs or charting a course towards business success, their expertise is the compass that steers you towards clarity, confidence, and achievement in the ever-changing legal landscape.",
  },
  {
    id: 6,
    icon: "fas fa-sack-dollar",
    title: "Finance & Investment",
    link: "/finance?subcat=investment",
    description:
      "Our finance and investment genies provide guidance and expertise for you or your business in managing your finances, making investment decisions and achieving your financial goals. They offer personalized financial planning services tailored to the specific needs and objectives for you.",
  },
];

function AppServices() {
  return (
    <section id="services" className="block services-block">
      <Container fluid>
        <div className="title-holder" id="title-holder">
          <h2>Popular services</h2>
          <div className="subtitle">popular services we provide</div>
        </div>
        <Row>
          {servicesData.map((services) => {
            return (
              <Col sm={4} className="holder" key={services.id}>
                <a href={services.link} id="service-link">
                  <div className="icon">
                    <i className={services.icon}></i>
                  </div>
                  <h3>{services.title}</h3>
                  <p>{services.description}</p>
                </a>
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
}

export default AppServices;

