import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import DomesticItenary from "./domesticItenary";
import EducationVisa from "./educationVisa";
import WorkVisa from "./workVisa";
import TravelVisa from "./travelVisa";
import InternationalItenary from "./internationalItenary";
import VisaAdvisor from "./visa";

function Travelgenie() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const subcat = searchParams.get("subcat");
  useEffect(() => {
    const section = document.querySelector("#" + subcat);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <>
      {/* <EducationVisa />
      <WorkVisa/>
      <TravelVisa/> */}
      <VisaAdvisor/>
      <InternationalItenary />
      <DomesticItenary/>
    </>
  );
}

export default Travelgenie;
