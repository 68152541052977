import React from "react";
import { useNavigate } from "react-router-dom";
import GenieCard from "../genie_card";

function CentralGovtSchemeGenie() {
  const genieData = [
    {
      id: 1,
      name: "Atul Jain",
      education_title: "Central Govt. Scheme Advisor",
      education_value: "Central Govt. Scheme Advisor",
      experience_title: null,
      experience_value: null,
      photo: require("../../assets/genieImages/default_genie.jpeg"),
      designation: "Central Govt. Scheme Advisor",
      duration1: 30,
      oprice1: 599,
      dprice1: 249,
      duration2: 50,
      oprice2: 899,
      dprice2: 399,
      services: [
        "Scheme Awareness: Informing clients about various central government schemes available across sectors like agriculture, education, health, and employment.",
        "Eligibility Check: Helping individuals or businesses determine if they qualify for specific government schemes and benefits.",
        "Application Assistance: Guiding clients through the application process for government schemes, ensuring all required documentation is provided.",
        "Subsidy and Grant Information: Advising on available subsidies, grants, and financial support provided under different schemes.",
        "Benefit Calculation: Assisting clients in calculating potential benefits or reimbursements under certain schemes.",
        "Loan and Credit Assistance: Providing information about government-backed loan programs and interest subsidies for eligible individuals or businesses.",
        "Compliance and Documentation Support: Ensuring that clients meet all the legal and regulatory requirements to apply for and benefit from government schemes.",
        "Updates on New Schemes: Keeping clients updated on newly launched central government schemes and policy changes.",
        "Dispute Resolution: Offering guidance on how to resolve disputes or issues related to scheme benefits and entitlements.",
        "Sector-Specific Guidance: Providing specialized advice for schemes related to sectors like MSMEs, housing, agriculture, and digital entrepreneurship.",
      ],
    },
  ];
  const navigate = useNavigate();
  return (
    <section id="central" className="block teams-block">
      <GenieCard title="Central Govt. Schemes Advisor" genieData={genieData} />
    </section>
  );
}

export default CentralGovtSchemeGenie;
