import React, { useEffect }  from "react";
import { useLocation } from "react-router-dom";
import PsychologistGenie from "./pyschologist";
import NutritionGenie from "./nutritionist";
import YogaGenie from "./yoga";
import GymGenie from "./gym";
import GynaeGenie from "./gynae";

function HealthGenie() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const subcat = searchParams.get("subcat");
  useEffect(() => {
    const section = document.querySelector("#" + subcat);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <>
      <YogaGenie />
      <GynaeGenie />
      <PsychologistGenie />
      <GymGenie />
      <NutritionGenie />
    </>
  );
}

export default HealthGenie;
