import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { goToBookingPage } from "../bookingPage";
import { useNavigate } from "react-router-dom";
import GenieCard from "../genie_card";

function GovtExamGenie() {
  const genieData = [
    {
      id: 1,
      name: "Krishna Jain",
      education_title: "Education",
      education_value: "B.Tech from GIT (Jaipur)",
      experience_title: "Current Job",
      experience_value: "GM at Food Corporation of India",
      photo: require("../../assets/genieImages/default_genie.jpeg"),
      designation: "Govt. Exams Advisor",
      duration1: 30,
      oprice1: 599,
      dprice1: 249,
      duration2: 50,
      oprice2: 899,
      dprice2: 399,
      services: [
        "Exam Preparation Strategies: Offering tips and techniques for effective study plans tailored to government exams.",
        "Syllabus Breakdown: Detailed guidance on covering the entire syllabus within a specific time frame.",
        "Study Material Recommendations: Suggesting the best resources, books, and online materials for preparation.",
        "Time Management: Advising on how to allocate study time efficiently for different subjects.",
        "Mock Tests and Practice Papers: Recommending and reviewing mock tests and practice exams.",
        "Eligibility and Application Process: Helping with understanding eligibility criteria and how to apply for government exams.",
        "Current Affairs Guidance: Providing insights and strategies to stay updated on current events, a critical aspect of most government exams.",
        "Interview and Personality Test Preparation: Offering tips and mock interviews for the personal interview phase.",
        "Stress Management: Advising on techniques to manage exam pressure and maintain focus.",
        "Result Analysis and Feedback: Reviewing exam performance and offering feedback for improvement.",
      ],
    },
  ];
  const navigate = useNavigate();
  return (
    <section id="govtExam" className="block teams-block">
      <GenieCard title="Talk to Govt. Exam Advisor" genieData={genieData} />
    </section>
  );
}

export default GovtExamGenie;
