import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import img1 from "../assets/images/about.jpg";

function AppAbout() {
  const html = 80;
  const responsive = 95;
  const photoshop = 60;

  return (
    <section id="about" className="block about-block">
      <Container fluid>
        <div className="title-holder" id="title-holder">
          <h2>About Us</h2>
          <div className="subtitle">learn more about us</div>
        </div>
        <Row>
          <Col sm={6}>
            <Image src={img1} />
          </Col>
          <Col sm={6}>
            <p>
              At Genie Tank, we believe that every day brings new challenges and
              opportunities for growth. Our platform is designed to provide you
              with timely and practical advice to navigate life's ups and downs
              with confidence and clarity.
            </p>
            <p>
              Founded on the principles of empowerment and support, Genie Tank
              is your trusted partner in making informed decisions and achieving
              your goals, one day at a time. Whether you're facing career
              dilemmas, health concerns, legal issues, or simply seeking
              inspiration for fashion and lifestyle or personal development,
              we're here to help.
            </p>
            <p>
              Our team of experienced advisors and experts are dedicated to
              provide you with relevant and actionable guidance tailored to your
              unique needs and circumstances. We understand that every
              individual's journey is different and we're committed to
              support you every step of the way. With a focus on
              accessibility and inclusivity, Genie Tank aims to break down
              barriers to knowledge and empower individuals from all walks of
              life to thrive. Our user-friendly interface and diverse range of
              topics ensure that everyone can find the advice they need, when
              they need it.
            </p>
            <p>
              Whether you're a student navigating the challenges of academic
              life, a professional seeking career advancement, or simply looking
              to improve your overall well-being, Genie Tank is your go-to
              resource for reliable advice and support.
            </p>
            <p>
              Join our community today and embark on a journey of growth,
              discovery, and empowerment with Genie Tank. Together, let's
              navigate life's complexities and embrace the opportunities that
              each new day brings.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default AppAbout;
