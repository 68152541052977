import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import { goToBookingPage } from "../bookingPage";
import GenieCard from "../genie_card";

function CivilGenie() {
  const genieData = [
    {
      id: 1,
      name: "Utkarsh Sharma",
      education_title: "Designation",
      education_value: "Civil Lawyer",
      experience_title: "Experience",
      experience_value: "Advocate since 2017 (Tis Hazari Court, Delhi)",
      photo: require("../../assets/genieImages/utkarsh_law.jpeg"),
      designation: "Civil Lawyer",
      duration1: 30,
      oprice1: 499,
      dprice1: 249,
      duration2: 50,
      oprice2: 649,
      dprice2: 399,
      services: [
        "Contract Drafting and Review: Advising on the creation, review, and negotiation of contracts to ensure they are legally sound and protect the client's interests.",
        "Dispute Resolution: Providing guidance on resolving disputes through mediation, arbitration, or negotiation before resorting to litigation.",
        "Litigation Representation: Representing clients in civil court cases involving contract disputes, property issues, and other civil matters.",
        "Property and Real Estate Law: Advising on property transactions, lease agreements, property disputes, and real estate investments.",
        "Tort Law: Providing advice on claims related to personal injury, negligence, and defamation.",
        "Family Law Matters: Offering legal advice on issues such as divorce, child custody, alimony, and property division.",
        "Employment Law: Advising on employment contracts, wrongful termination, discrimination claims, and workplace disputes.",
        "Consumer Protection: Assisting clients with issues related to consumer rights, fraud, and deceptive business practices.",
        "Estate Planning and Probate: Advising on the preparation of wills, trusts, and estate planning to ensure proper distribution of assets and handling of probate.",
        "Business Law: Providing legal advice for businesses, including formation, compliance, contracts, and corporate governance.",
        "Landlord-Tenant Disputes: Advising on rental agreements, eviction procedures, and resolving disputes between landlords and tenants.",
        "Insurance Claims: Assisting with disputes involving insurance claims, coverage issues, and policy interpretation.",
        "Intellectual Property: Advising on the protection of intellectual property rights, including trademarks, copyrights, and patents.",
        "Civil Rights: Providing legal assistance in cases involving civil rights violations and discrimination.",
        "Debt Collection: Advising on strategies for collecting debts, including pursuing legal action if necessary.",
        "Legal Compliance: Ensuring clients comply with relevant laws and regulations in various aspects of their personal and professional lives.",
        "Risk Management: Offering advice on managing legal risks associated with business operations, contracts, and personal transactions.",
        "Settlement Negotiation: Assisting in negotiating settlements to resolve disputes efficiently and avoid lengthy litigation.",
        "Representation in Administrative Hearings: Providing representation in administrative hearings related to civil matters, such as licensing issues or regulatory compliance.",
      ],
    },
  ];
  const navigate = useNavigate();
  return (
    <section id="civil" className="block teams-block">
      <GenieCard title="Civil Lawyer Genie" genieData={genieData} />
    </section>
  );
}

export default CivilGenie;
