import React from "react";
import { useNavigate } from "react-router-dom";
import GenieCard from "../genie_card";

function AnniversaryGenie() {
  const genieData = [
    {
      id: 1,
      name: "Rahul Gupta",
      education_title: "Events",
      education_value: "Anniversary Events Planning Advisor",
      experience_title: "Current Job",
      experience_value: "Event Manager",
      photo: require("../../assets/genieImages/default_genie.jpeg"),
      designation: "Anniversary Events Planning Advisor",
      duration1: 30,
      oprice1: 599,
      dprice1: 249,
      duration2: 50,
      oprice2: 899,
      dprice2: 399,
      services: [
        "Event Conceptualization and Theme Selection: Helping clients choose themes and create memorable experiences based on personal preferences, milestones, and relationships.",
        "Budget Planning and Management: Advising on how to allocate resources effectively, ensuring that all event expenses fit within the client's budget.",
        "Venue Selection: Offering suggestions on appropriate venues that fit the anniversary’s scale, theme, and budget, whether intimate gatherings or larger celebrations.",
        "Vendor Coordination: Recommending and managing relationships with caterers, decorators, photographers, and entertainment providers.",
        "Guest Management: Handling invitations, RSVPs, seating arrangements, and other logistics related to guests.",
        "Timeline Creation: Developing a schedule for the event, including key milestones like speeches, toasts, cake-cutting, and performances.",
        "Decor and Styling: Advising on decoration ideas, floral arrangements, and overall aesthetic to enhance the atmosphere.",
        "Logistical Support: Providing behind-the-scenes coordination, including event day management to ensure smooth execution.",
        "Personalized Touches: Assisting in creating personal moments or elements (such as video montages, speeches, or custom gifts) that reflect the couple's journey.",
        "Post-Event Follow-Up: Coordinating after-event tasks like thank-you notes or post-event feedback collection.",
      ],
    },
  ];
  const navigate = useNavigate();
  return (
    <section id="anniversary" className="block teams-block">
      <GenieCard title="Anniversary Event Advisor" genieData={genieData} />
    </section>
  );
}

export default AnniversaryGenie;
