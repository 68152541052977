import React, { useEffect }  from "react";
import { useLocation } from "react-router-dom";
import CivilGenie from "./civil";
import CrimeGenie from "./crime";
import CorporateGenie from "./corporate";

function LegalGenie() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const subcat = searchParams.get("subcat");
  useEffect(() => {
    const section = document.querySelector("#" + subcat);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <>
      <CivilGenie/>
      <CorporateGenie/>
      <CrimeGenie/>
    </>
  );
}

export default LegalGenie;
