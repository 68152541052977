import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Appnewbookgenie() {
  const educationGenieCategories = [
    {
      id: "iit",
      name: "Talk to an IITian",
    },
    {
      id: "neet",
      name: "Talk to NEET Advisor",
    },
    {
      id: "commerce",
      name: "Talk to Commerce Advisor",
    },
    {
      id: "abroad",
      name: "Talk to International Students Advisor",
    },
    {
      id: "career",
      name: "Career Advisor",
    },
    {
      id: "coaching",
      name: "Coaching Advisor",
    },
  ];
  const legalGenieCategories = [
    {
      id: "civil",
      name: "Civil Lawyer",
    },
    {
      id: "corporate",
      name: "Corporate Lawyer",
    },
    {
      id: "crime",
      name: "Criminal Lawyer",
    },
  ];
  const healthGenieCategories = [
    {
      id: "yoga",
      name: "Yoga Advisor",
    },
    {
      id: "gynae",
      name: "Teenage Health Advisor",
    },
    {
      id: "pyschologist",
      name: "Psychologist",
    },
    {
      id: "gym",
      name: "Gym Advisor",
    },
    {
      id: "nutritionist",
      name: "Diet & Nutritionist Advisor",
    },
  ];
  const financeGenieCategories = [
    {
      id: "ca",
      name: "CA",
    },
    {
      id: "investment",
      name: "Investments Advisor",
    },
  ];
  return (
    <section id="newbookgenie" className="block services-block">
      <Container fluid className="booking-container">
        <div className="title-holder" id="title-holder">
          <h2>Book Genie</h2>
          <div className="subtitle">book your genie now</div>
        </div>
        <center>
          <Row className="portfoliolist">
            <Col sm={3} key="1">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  Education
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {educationGenieCategories.map((genieCat) => {
                    const url = "/education?subcat=" + genieCat.id;
                    return (
                      <Dropdown.Item href={url}>{genieCat.name}</Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <br />
            <br />
            <Col sm={3} key="2">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  Legal
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {legalGenieCategories.map((genieCat) => {
                    const url = "/legal?subcat=" + genieCat.id;
                    return (
                      <Dropdown.Item href={url}>{genieCat.name}</Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <br />
            <br />
            <Col sm={3} key="3">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  Health & Lifestyle
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {healthGenieCategories.map((genieCat) => {
                    const url = "/health?subcat=" + genieCat.id;
                    return (
                      <Dropdown.Item href={url}>{genieCat.name}</Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <br />
            <br />
            <Col sm={3} key="4">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  Finance
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {financeGenieCategories.map((genieCat) => {
                    const url = "/finance?subcat=" + genieCat.id;
                    return (
                      <Dropdown.Item href={url}>{genieCat.name}</Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </center>
      </Container>
    </section>
  );
}

export default Appnewbookgenie;
