import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import { goToBookingPage } from "../bookingPage";
import GenieCard from "../genie_card";

function YogaGenie() {
  const navigate = useNavigate();
  const genieData = [
    {
      id: 1,
      name: "Rahul Yadav ",
      education_title: "Education",
      education_value: "Msc in Yoga Science",
      experience_title: "Experience",
      experience_value: "10 years",
      photo: require("../../assets/genieImages/rahul_yoga.jpeg"),
      designation: "Yoga Genie",
      duration1: 30,
      oprice1: 199,
      dprice1: 99,
      duration2: null,
      oprice2: null,
      dprice2: null,
      services: [
        "Personalized Yoga Plans: Creating customized yoga routines based on individual goals, needs, and physical conditions.",
        "Yoga Technique Guidance: Providing instruction on proper alignment, breathing techniques, and effective practice methods.",
        "Stress Relief: Advising on yoga practices to manage and reduce stress, including relaxation and mindfulness techniques.",
        "Flexibility and Strength Building: Offering guidance on yoga poses and sequences to improve flexibility, strength, and balance.",
        "Injury Prevention and Recovery: Advising on yoga practices that help prevent injuries and support recovery from physical ailments.",
        "Mental Clarity and Focus: Teaching techniques to enhance mental clarity, concentration, and emotional balance through yoga.",
        "Breathing Exercises: Providing instruction on pranayama (breath control) to enhance overall health and relaxation.",
        "Mindfulness and Meditation: Offering guidance on incorporating mindfulness and meditation practices into daily life.",
        "Yoga for Specific Health Conditions: Tailoring yoga practices to address specific health issues such as chronic pain, hypertension, or anxiety.",
        "Lifestyle and Wellness Integration: Advising on how to integrate yoga principles into daily life for overall wellness and healthy living.",
        "Yoga for Athletes: Providing specialized yoga routines to enhance athletic performance and aid in recovery.",
        "Yoga for Beginners: Offering foundational yoga practices and advice for individuals new to yoga.",
        "Advanced Practice Techniques: Guiding advanced practitioners in exploring deeper aspects of yoga practice, including advanced poses and techniques.",
        "Holistic Wellness: Advising on the integration of yoga with other wellness practices such as nutrition, meditation, and healthy living."
    ]
    
    }
  ];
  return (
    <section id="yoga" className="block teams-block">
      <GenieCard title="Yoga Genie" genieData={genieData}/>
    </section>
  );
}

export default YogaGenie;
