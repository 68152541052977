import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";
function AppContact() {
  const form = useRef();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    message: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("option", name, value);
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const navigate = useNavigate();
  function handleDismiss() {
    navigate("/");
  }
  function didTapSubmit(event) {
    event.preventDefault();
    sendMail();
  }

  function sendMail() {
    emailjs
      .send(
        "service_yverrb7",
        "template_ik4dn4i",
        formData,
        "PDIAdXVcMoijbLXha"
      )
      .then((result) => {
        console.log(result.text);
        toast.success(
          <>
            Form submitted successfully!
            <br />
            We will get in touch with you soon
          </>,
          {
            onClose: handleDismiss,
          }
        );
      })
      .catch((error) => {
        console.log(error.text);
        alert("Failed to submit form! \n Please try again");
      });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section id="contact" className="block contact-block">
      <Container fluid>
        <div className="title-holder" id="title-holder">
          <h2>Contact us</h2>
          <div className="subtitle">get connected with us</div>
        </div>
        <Form className="contact-form" ref={form} onSubmit={didTapSubmit}>
          <Row>
            <Col sm={4}>
              <Form.Control
                type="text"
                placeholder="Enter your full name"
                name="name"
                value={formData.name}
                required
                onChange={handleChange}
              />
            </Col>
            <Col sm={4}>
              <Form.Control
                type="email"
                placeholder="Enter your email address"
                name="email"
                value={formData.email}
                required
                onChange={handleChange}
              />
            </Col>
            <Col sm={4}>
              <Form.Control
                type="tel"
                placeholder="Enter your contact number"
                name="contact"
                value={formData.contact}
                required
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Control
                as="textarea"
                placeholder="Enter your contact message"
                name="message"
                value={formData.message}
                required
                onChange={handleChange}
              />
            </Col>
          </Row>
          <div className="btn-holder">
            <Button type="submit">Submit</Button>
          </div>
        </Form>
        <ToastContainer />
      </Container>
      {/* <Container fluid>
        <div className="contact-info">
          <ul>
            <li>
              <a href="mailto:genietank5@gmail.com" rel="noreferrer" target="_blank"><i className="fas fa-envelope"/></a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/genie-tank/" rel="noreferrer" target="_blank"><i class="fas fab fa-linkedin-in"/></a>
            </li>
            <li>
              <a href="https://www.instagram.com/genietank/" rel="noreferrer" target="_blank" ><i class="fas fa-brands fa-instagram"/></a>
            </li>
            <li>
              <i className="fas fa-phone"></i>
              +91-8368667682
            </li>
          </ul>
        </div>
      </Container> */}
    </section>
  );
}

export default AppContact;
