import React from "react";
import { useNavigate } from "react-router-dom";
import GenieCard from "../genie_card";

function BrideGroomApparel() {
  const genieData = [
    {
      id: 1,
      name: "Aditi Singh",
      education_title: "Fashion",
      education_value: "Apparel Advisor",
      experience_title: "Current Job",
      experience_value: "Fashion Designer",
      photo: require("../../assets/genieImages/default_genie.jpeg"),
      designation: "Fashion Designer",
      duration1: 30,
      oprice1: 599,
      dprice1: 249,
      duration2: 50,
      oprice2: 899,
      dprice2: 399,
      services: [
        "Style Consultation: Offering advice on choosing a wedding day look that aligns with the couple's preferences, wedding theme, and cultural traditions.",
        "Fabric Selection: Recommending appropriate fabrics for comfort, seasonality, and aesthetic appeal.",
        "Customization and Fitting: Guiding clients through custom design options or tailoring existing outfits for a perfect fit.",
        "Color Coordination: Assisting in selecting colors that complement the couple's complexion and harmonize with the wedding theme.",
        "Accessory Selection: Recommending accessories such as jewelry, shoes, veils, ties, and pocket squares to complete the look.",
        "Bridal Gown Selection: Helping the bride choose a wedding gown that suits her body type, personal style, and comfort.",
        "Groom’s Attire Guidance: Advising the groom on selecting a tuxedo, suit, or cultural attire that matches the occasion.",
        "Outfit Maintenance: Providing tips on caring for and preserving wedding outfits before and after the ceremony.",
        "Cultural or Religious Dress Compliance: Offering insights on how to incorporate traditional or religious dress codes into modern wedding attire.",
        "Event-Specific Styling: Offering recommendations for secondary events like the rehearsal dinner, reception, or post-wedding brunch.",
        "Budget Management for Apparel: Helping couples make cost-effective decisions regarding their attire without compromising on style.",
      ],
    },
  ];
  const navigate = useNavigate();
  return (
    <section id="brideGroomApparel" className="block teams-block">
      <GenieCard
        title="Bride and Groom Apparel Advisor"
        genieData={genieData}
      />
    </section>
  );
}

export default BrideGroomApparel;
