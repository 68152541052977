import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { goToBookingPage } from "./bookingPage";
import { useNavigate } from "react-router-dom";
import ComingSoon from "./comingsoon";

const GenieCard = ({ title, genieData }) => {
  const navigate = useNavigate();
  return (
    <Container fluid>
      <div className="title-holder" id="title-holder">
        <h2>{title}</h2>
      </div>
      {genieData.length === 0 ? (
        <ComingSoon />
      ) : (
        <Row>
          {genieData.map((genie) => {
            return (
              <Col sm={4}>
                <Card style={{ width: "18rem" }}>
                  <Card.Img
                    variant="top"
                    src={genie.photo}
                    className="genie-image"
                  />
                  <Card.Body>
                    <center>
                      <Card.Title>{genie.name}</Card.Title>
                      <Card.Text>
                        <center>{genie.education_value}</center>
                        {genie.experience_title != null? (
                          <>
                            <b>{genie.experience_title}: </b>
                            {genie.experience_value} <br />
                          </>
                        ) : null}
                        Rs <strike>{genie.oprice1}</strike> {genie.dprice1} (
                        {genie.duration1} mins)
                        <br />
                        {genie.duration2 != null? (
                          <>Rs <strike>{genie.oprice2}</strike> {genie.dprice2} (
                            {genie.duration2} mins)</>
                        ): null}
                      </Card.Text>
                      <Button
                        variant="primary"
                        onClick={() => goToBookingPage(navigate, genie)}
                      >
                        Book Now
                      </Button>
                    </center>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      )}
    </Container>
  );
};

export default GenieCard;
