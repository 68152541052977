import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";
// import Link from "@material-ui/core/Link"
function AppRegister() {
  const form = useRef();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    domain: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("option", name, value);
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const navigate = useNavigate();
  function handleDismiss() {
    navigate("/");
  }
  function didTapSubmit(event) {
    event.preventDefault();
    sendMail();
  }

  function sendMail() {
    emailjs
      .send(
        "service_yverrb7",
        "template_ik4dn4i",
        formData,
        "PDIAdXVcMoijbLXha"
      )
      .then((result) => {
        console.log(result.text);
        toast.success(
          <>
            Form submitted successfully!
            <br />
            We will get in touch with you soon
          </>,
          {
            onClose: handleDismiss,
          }
        );
      })
      .catch((error) => {
        console.log(error.text);
        alert("Failed to submit form! \n Please try again");
      });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section id="contact" className="block contact-block">
      <Container fluid>
        <div className="title-holder" id="title-holder">
          <h2>Register as a Genie</h2>
          <div className="subtitle">get registered with us</div>
        </div>
        {/* <Form className="contact-form" ref={form} onSubmit={didTapSubmit}>
          <Row>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              placeholder="Enter your full name"
              onChange={handleChange}
              required
            />
          </Row>
          <Row>
            <Form.Control
              type="email"
              placeholder="Enter your email address"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Row>
          <Row>
            <Form.Control
              type="tel"
              name="contact"
              value={formData.contact}
              onChange={handleChange}
              placeholder="Enter your contact number/ whatsapp number"
              required
            />
          </Row>
          <Row>
            <Form.Control
              type="text"
              name="domain"
              value={formData.domain}
              placeholder="Areas of Expertise"
              onChange={handleChange}
              required
            />
          </Row>
          <div className="btn-holder">
            <Button type="submit">Submit</Button>
          </div>
        </Form>
        <ToastContainer /> */}
        <center>
          Please fill the google form to register on our platform.
          <br />
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSc2Yt1TF-8Slg12UYvyHxQVlGMdZUEPyvnxMKO5JU7LC556IA/viewform">
            https://docs.google.com/forms/d/e/1FAIpQLSc2Yt1TF-8Slg12UYvyHxQVlGMdZUEPyvnxMKO5JU7LC556IA/viewform
          </a>
        </center>
      </Container>
    </section>
  );
}

export default AppRegister;
