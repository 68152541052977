import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { goToBookingPage } from "../bookingPage";
import { useNavigate } from "react-router-dom";
import GenieCard from "../genie_card";

function InvestmentGenie() {
  const genieData = [
    {
      id: 1,
      name: "Sparsh Vijay",
      education_title: "Designation",
      education_value: "Stock Market / Fund Manager",
      experience_title: "Experience",
      experience_value: "4.5 years",
      photo: require("../../assets/genieImages/sparsh_investment.jpeg"),
      designation: "Investment Genie",
      duration1: 30,
      oprice1: 249,
      dprice1: 99,
      duration2: 60,
      oprice2: 399,
      dprice2: 149,
      services: ["Creating personalised investment plans based on your goals", "Analysing your portfolio", "Providing financial education", "Recommending stocks", "Reviewing IPOs"]
    },
  ];
  const navigate = useNavigate();
  return (
    <section id="investment" className="block teams-block">
      <GenieCard title="Investment Genie" genieData={genieData} />
    </section>
  );
}

export default InvestmentGenie;
