import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import testimonials from '../components/testimonials.json';


function Testimonials() {
  const quote = require("../assets/images/quote.png")
  const rating = require("../assets/images/rating.png")
  const navigate = useNavigate();
  return (
    <section id="teams" className="block testimonial-block">
      <Container fluid>
        <div className="title-holder" id="title-holder">
          <h2>Testimonials</h2>
          <div className="subtitle">some of our customer reviews</div>
        </div>
        <Row>
        <div>
        <Carousel variant="dark" interval={1000} style={{ padding:'20px', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'}} >
            {testimonials.map((c) => {
            return (

                <Carousel.Item interval={5000}>
                <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignContent:'center', alignItems:'center'}}>
                  <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignContent:'center', paddingBottom:'20px'}}>
                  <Image src={quote} />
                  </div>
                  <div style={{ minHeight: '350px', height: "auto", width:'80%', color: "black", textAlign:'center'}}>
                      <p>{c.testimonial}</p>
                      <p style={{color:"black", textAlign:'center '}}>{c.name}</p>
                  </div>
                </div>
                </Carousel.Item>
            );
            })}
        </Carousel>
        </div>
        </Row>
      </Container>
    </section>
  );
}

export default Testimonials;
