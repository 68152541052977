import React from "react";
import { useNavigate } from "react-router-dom";
import GenieCard from "../genie_card";

function BirthdayGenie() {
  const genieData = [
    {
      id: 1,
      name: "Ajay Mahajan",
      education_title: "Events",
      education_value: "Birthday Events Planning Advisor",
      experience_title: "Current Job",
      experience_value: "Event Manager",
      photo: require("../../assets/genieImages/default_genie.jpeg"),
      designation: "Birthday Events Planning Advisor",
      duration1: 30,
      oprice1: 599,
      dprice1: 249,
      duration2: 50,
      oprice2: 899,
      dprice2: 399,
      services: [
        "Theme and Concept Development: Assisting clients in selecting unique and age-appropriate themes based on the interests and preferences of the birthday celebrant.",
        "Budget Management: Offering advice on budget allocation to ensure that all elements of the birthday party are covered without exceeding financial limits.",
        "Venue Selection: Suggesting venues that align with the party's scale, theme, and guest list size.",
        "Vendor Coordination: Connecting clients with reliable vendors such as caterers, decorators, entertainers, and photographers.",
        "Invitation and Guest Management: Assisting with the design, distribution, and tracking of invitations, as well as organizing RSVPs and guest lists.",
        "Entertainment Planning: Recommending activities, games, or entertainment suitable for the age group and interests of the birthday guests.",
        "Decor and Styling: Offering advice on decorations, color schemes, and aesthetic arrangements that align with the chosen theme.",
        "Catering and Menu Selection: Helping to design a menu that suits the theme and caters to guests' dietary preferences and needs.",
        "Logistical Support: Coordinating all logistics on the day of the event, ensuring smooth operations from setup to cleanup.",
        "Personalized Elements: Assisting in creating personalized party favors, cakes, or special moments like video messages or speeches.",
        "Post-Event Coordination: Handling post-party tasks, such as distributing thank-you notes or gathering feedback.",
      ],
    },
  ];
  const navigate = useNavigate();
  return (
    <section id="birthday" className="block teams-block">
      <GenieCard title="Birthday Event Advisor" genieData={genieData} />
    </section>
  );
}

export default BirthdayGenie;
