import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { goToBookingPage } from "../bookingPage";
import { useNavigate } from "react-router-dom";
import GenieCard from "../genie_card";

function IITGenie() {
  const genieData = [
    {
      id: 1,
      name: "Puneet",
      education_title: "Education",
      education_value: "IIT Kanpur (Civil)",
      experience_title: "Current Job",
      experience_value: "Engineer at NHPC",
      photo: require("../../assets/genieImages/puneet_iit.jpeg"),
      designation: "IIT Genie",
      duration1: 30,
      oprice1: 599,
      dprice1: 249,
      duration2: 50,
      oprice2: 899,
      dprice2: 399,
      services: [
        "Study Strategies: Best ways to prepare for JEE Main and Advanced.",
        "Time Management: How to balance school, coaching, and self-study.",
        "Stress Management: Techniques to stay calm and focused.",
        "Previous Year Papers: How to effectively utilise past papers for practice.",
        "Exam Tips: Last-minute preparation and exam day strategies.",
        "Resource Recommendations: Best books and online resources to follow.",
        "Mock Tests: How to analyse and learn from your mock test performances.",
      ],
    },
    {
      id: 2,
      name: "Kushagra Singh",
      education_title: "Education",
      education_value: "IIT Delhi (Mechanical Engineering)",
      experience_title: "JEE Advance Rank",
      experience_value: "2500",
      photo: require("../../assets/genieImages/kushagra_iit.jpeg"),
      designation: "IIT Genie",
      duration1: 30,
      oprice1: 599,
      dprice1: 249,
      duration2: 50,
      oprice2: 899,
      dprice2: 399,
      services: [
        "Study Strategies: Best ways to prepare for JEE Main and Advanced.",
        "Time Management: How to balance school, coaching, and self-study.",
        "Stress Management: Techniques to stay calm and focused.",
        "Previous Year Papers: How to effectively utilise past papers for practice.",
        "Exam Tips: Last-minute preparation and exam day strategies.",
        "Resource Recommendations: Best books and online resources to follow.",
        "Mock Tests: How to analyse and learn from your mock test performances.",
      ],
    },
    {
      id: 3,
      name: "Deepak Malik",
      education_title: "Education",
      education_value: "IIT Roorkee (Civil)",
      experience_title: "JEE Advance Rank",
      experience_value: "7037",
      photo: require("../../assets/genieImages/default_genie.jpeg"),
      designation: "IIT Genie",
      duration1: 30,
      oprice1: 599,
      dprice1: 249,
      duration2: 50,
      oprice2: 899,
      dprice2: 399,
      services: [
        "Study Strategies: Best ways to prepare for JEE Main and Advanced.",
        "Time Management: How to balance school, coaching, and self-study.",
        "Stress Management: Techniques to stay calm and focused.",
        "Previous Year Papers: How to effectively utilise past papers for practice.",
        "Exam Tips: Last-minute preparation and exam day strategies.",
        "Resource Recommendations: Best books and online resources to follow.",
        "Mock Tests: How to analyse and learn from your mock test performances.",
      ],
    },
    {
      id: 4,
      name: "Neam Adil",
      education_title: "Education",
      education_value: "IIT Delhi (Engineering Physics)",
      experience_title: null,
      experience_value: "4549",
      photo: require("../../assets/genieImages/neam_iit.jpg"),
      designation: "IIT Genie",
      duration1: 30,
      oprice1: 599,
      dprice1: 249,
      duration2: 50,
      oprice2: 899,
      dprice2: 399,
      services: [
        "Study Strategies: Best ways to prepare for JEE Main and Advanced.",
        "Time Management: How to balance school, coaching, and self-study.",
        "Stress Management: Techniques to stay calm and focused.",
        "Previous Year Papers: How to effectively utilise past papers for practice.",
        "Exam Tips: Last-minute preparation and exam day strategies.",
        "Resource Recommendations: Best books and online resources to follow.",
        "Mock Tests: How to analyse and learn from your mock test performances.",
      ],
    },
  ];
  const navigate = useNavigate();
  return (
    <section id="iit" className="block teams-block">
      <GenieCard title="Talk to an IITian" genieData={genieData} />
    </section>
  );
}

export default IITGenie;
