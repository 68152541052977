import React from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import GenieCard from "../genie_card";

function Cagenie() {
  const genieData = [
    {
      id: 1,
      name: "Finance and Tax Advisor",
      education_title: "Designation",
      education_value: "Chartered Accountant",
      experience_title: null,
      photo: require("../../assets/genieImages/default_genie.jpeg"),
      designation: "CA Genie",
      duration1: 30,
      oprice1: 749,
      dprice1: 249,
      duration2: 60,
      oprice2: 1049,
      dprice2: 499,
      services: [
        "Developing strategies to minimize tax liabilities.",
        "Advising on tax-efficient investment strategies.",
        "Planning for tax impacts of financial decisions.",
        "Preparing and filing personal and business tax returns.",
        "Ensuring compliance with tax laws and regulations.",
        "Identifying eligible tax deductions and credits.",
        "Advising on maximizing deductions and credits to reduce taxable income.",
        "Ensuring adherence to tax regulations and deadlines.",
        "Handling correspondence with tax authorities.",
        "Advising on strategies to minimize estate and gift taxes.",
        "Planning for the transfer of wealth to heirs.",
        "Advising on tax implications of business decisions.",
        "Helping with business tax planning and compliance.",
        "Assisting with tax issues related to mergers, acquisitions, and restructuring.",
        "Assisting with audits and tax disputes.",
        "Negotiating with tax authorities to resolve issues."
    ]    
    }
  ];
  const navigate = useNavigate();
  return (
    <section id="ca" className="block teams-block">
      <GenieCard title = "CA Genie" genieData={genieData} />
    </section>
  );
}

export default Cagenie;
