import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function BookingPage() {

  const [time, setTime] = useState("")

  const [timeflag, setTimeflag] = useState(0)

  const [selectedDate, setSelectedDate] = useState(null);

  const location = useLocation();
  const form = useRef();
 
  const [duration, setDuration] = useState("")

  const [timings, setTimings] = useState({
    timings : []
  })
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    option: "",
    genieData: {},
  });

  useEffect(() => {
    if(duration === "30"){
      setTimings(["11:00-11:30 AM", "11:30-12:00 PM", "12:00-12:30 PM","12:30-1:00 PM", "1:00-1:30 PM", "1:30-2:00 PM","2:00-2:30 PM", "2:30-3:00 PM", "3:00-3:30 PM"]);
    }
    if(duration === "50"){
      setTimings(["11:00-11:50 AM", "12:00-12:50 PM", "1:00-1:50 PM", "2:00-2:50 PM", "3:00-3:50 PM","4:00-4:50PM", "5:00-5:50PM", "6:00-6:50PM", "7:00-7:50PM"]);
    }
    if(duration === "40"){
      setTimings(["11:00-11:40 AM", "11:30-12:10 PM", "12:00-12:40 PM","12:30-1:10 PM", "1:00-1:40 PM", "1:30-2:10 PM","2:00-2:40 PM", "2:30-3:10 PM", "3:00-3:40 PM"]);
    }
    if(duration === "60"){
      setTimings(["11:00-12:00 AM", "12:00-1:00 PM", "1:00-2:00 PM", "2:00-3:00 PM", "3:00-4:00 PM","4:00-5:00 PM", "5:00-6:00 PM", "6:00-7:00 PM", "7:00-8:00 PM"]);
    }
  }, [duration]);

  const handleTimeSelection = (e) => {
    const { value } = e.target;
    setTime(value);
    setTimeflag(1)
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDuration(value);

    setFormData({
      ...formData,
      [name]: value,
    });
    console.log(value, timings.length);
  };

  const navigate = useNavigate();
  function handleDismiss() {
    navigate("/");
  }
  
  let genie = {
    id: "genie",
    oprice: 1000,
    dprice: 500,
    name: "Kripali",
    photo: require("../assets/genieImages/sajal_mbbs.jpeg"),
    services:[]
  };
  if (location.state) {
    genie = location.state.data;
  }

  
  const scheduleSubmit = (e) => {
    e.preventDefault();
    // console.log(genie)
    if(!timeflag){
      alert("Select Time")
    }

    timeflag && navigate('/booking-details', {
      state: {
        duration,
        time,
        selectedDate,
        genie
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const today = new Date();
  const minDate = new Date(today);
  minDate.setDate(minDate.getDate() + 1); // 1 day ahead of today

  const maxDate = new Date(today);
  maxDate.setDate(maxDate.getDate() + 14);

  const [showMore, setShowMore] = useState(false);
  // const firstFiveServices = services.slice(0, 5);
  // const remainingServices = services.slice(5);

  const handleShowMore = () => {
    setShowMore(true);
  };

  return (
    <section className="block contact-block cb bp-container">
      <Container fluid className="contact-block-info cb-section">
        <span>
          <h1>{genie.name}</h1>
        </span>
        <Card style={{ width: "18rem" }}>
            <Card.Img variant="top" src={genie.photo} className="genie-image" />
            <Card.Body className="cb-title">
              <center>
                <Card.Title><b>{genie.designation}</b></Card.Title>
              </center>
            </Card.Body>
          </Card>
          <div style={{display:'flex', flexDirection:'column', alignItems:'center', margin:'2rem 1rem'}}>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'centre', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px'}}>
              {genie.education_title && <div className="bp-title" style={{padding:'10px', margin:'10px', fontSize:'18px'}}>{genie.education_title}</div>}
              {genie.education_value &&<div className="bp-value" style={{padding:'10px', fontSize:'18px', margin:'10px', fontWeight:'bolder'}}>{genie.education_value}</div>}
            </div>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'centre', margin:'10px', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px'}}>
              {genie.experience_title && <div className="bp-title" style={{padding:'10px', margin:'10px', fontSize:'18px'}}>{genie.experience_title}</div>}
              {genie.experience_value && <div className="bp-value" style={{padding:'10px', fontSize:'18px', margin:'10px', fontWeight:'bolder'}}>{genie.experience_value}</div>}
            </div>
          </div>
          <div style={{padding:'10px', marginBottom:'10px',boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px'}}>{genie.name} can help you with :</div>
          <div id="genie-services">
            {((genie.services).slice(0, 5)).map((service)=>{
              return(
                <div id="genieService">{service}</div>
              )
            })}
            {showMore && ((genie.services).slice(5)).map((service)=>{
              return(
                <div id="genieService">{service}</div>
              )
            })}

            {!showMore && ((genie.services).slice(5)).length > 0 && (
                    <Button onClick={handleShowMore} style={{width:'12rem', margin:'10px', alignSelf:'center'}}>Show More...</Button>
                  )}
          </div>
      </Container>
      <Container fluid className="cb-section" style={{padding:'0 50px'}}>
        <div className="title-holder" id="title-holder">
          <h2>Book Now</h2>
          <div className="subtitle">book your favourite genie</div>
        </div>
        <Form className="contact-form" ref={form} onSubmit={scheduleSubmit}>
          <h4><b>When should we meet?</b></h4>
          <Row style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="yyyy/MM/dd"
              placeholderText="Select a date"
              minDate={minDate}
              maxDate={maxDate}
              className="custom-datepicker"
              required
            />
          {/* <Card style={{ width: '7rem' , textAlign:'center'}}>
            <Card.Body>
              <Card.Title>Sat</Card.Title>
              <Card.Subtitle className="mb-2">27 Jul</Card.Subtitle>
            </Card.Body>
          </Card>
          <Card style={{ width: '7rem' , textAlign:'center'}}>
            <Card.Body>
              <Card.Title>Sun</Card.Title>
              <Card.Subtitle className="mb-2">28 Jul</Card.Subtitle>
            </Card.Body>
          </Card>
          <Card style={{ width: '7rem' , textAlign:'center'}}>
            <Card.Body>
              <Card.Title>Mon</Card.Title>
              <Card.Subtitle className="mb-2">29 Jul</Card.Subtitle>
            </Card.Body>
          </Card>
          <Card style={{ width: '7rem' , textAlign:'center'}}>
            <Card.Body>
              <Card.Title>Tue</Card.Title>
              <Card.Subtitle className="mb-2">30 Jul</Card.Subtitle>
            </Card.Body>
          </Card>
          <Card style={{ width: '7rem' , textAlign:'center'}}>
            <Card.Body>
              <Card.Title>Wed</Card.Title>
              <Card.Subtitle className="mb-2">31 Jul</Card.Subtitle>
            </Card.Body>
          </Card> */}
          </Row>
          <h4><b>Select Duration</b></h4>
          <Row>
          <Col sm={3}>
              <Form.Check
                type="radio"
                label={
                  "Rs " + genie.dprice1 + " (" + genie.duration1 + " mins)"
                }
                name="option"
                id="option1"
                value={genie.duration1}
                // checked={true}
                // onClick={handleChange}
                onChange={handleChange}
                required
              />
            </Col>
            {genie.duration2 != null ? (
              <>
                <Col>
                  <Form.Check
                    type="radio"
                    label={
                      "Rs " + genie.dprice2 + " (" + genie.duration2 + " mins)"
                    }
                    name="option"
                    id="option2"
                    value={genie.duration2}
                    onChange={handleChange}
                    // onClick={handleChange}
                    required
                  />
                </Col>
              </>
            ) : null}
          </Row>
          {(timings.length>1) && 
          <><h4><b>Select time of day</b></h4>
          <Row style={{display:'flex', flexDirection:'row', justifyContent:"normal"}}>
            {timings.map((time) => {
              return (
              // <Card style={{ width: '12rem' , margin:'5px 5px', textAlign:'center'}} onClick={handleTimeSelection(time)}>
              //   <Card.Body>
              //     <Card.Subtitle className="mb-2">{time.t}</Card.Subtitle>
              //   </Card.Body>
              // </Card>
              <Button id="timebtn" style={{ width: '12rem' , margin:'5px 5px', textAlign:'center' }} onClick={handleTimeSelection} value={time}>{time}</Button>
              )
            })}
          </Row></>}
          <div className="btn-holder" style={{marginTop:'80px'}}>
            <Button type="submit">Confirm Details</Button>
          </div>
        </Form>
        <ToastContainer />
      </Container>
    </section>
  );
}

export default BookingPage;

export function goToBookingPage(navigate, genieData) {
  navigate("/bookingpage", { state: { data: genieData } });
}