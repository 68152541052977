import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { goToBookingPage } from "../bookingPage";
import { useNavigate } from "react-router-dom";
import GenieCard from "../genie_card";

function NeetGenie() {
  const genieData = [
    {
      id: 1,
      name: "Puneet Jindal",
      education_title: "Education",
      education_value: "MBBS (Maulana Azad Medical College)",
      experience_title: "NEET Score",
      experience_value: "685",
      photo: require("../../assets/genieImages/puneet_mbbs.jpg"),
      designation: "MBBS Genie",
      duration1: 30,
      oprice1: 599,
      dprice1: 249,
      duration2: 50,
      oprice2: 899,
      dprice2: 399,
      services: [
        "Exam strategy and preparation",
        "How to balance between school studies and preparation",
        "Study material and resources (mock tests, previous year papers)",
        "How to stay motivated and prevent burnouts during preparations",
        "College application and admission process",
        "What to do in the last few weeks leading up to the exam to maximise performance"
      ]
    },
    {
      id: 2,
      name: "Sajal Jain",
      education_title: "Education",
      education_value: "MBBS (Jhalawar Medical College)",
      experience_title: "NEET Score",
      experience_value: "600",
      photo: require("../../assets/genieImages/sajal_mbbs.jpeg"),
      designation: "MBBS Genie",
      duration1: 30,
      oprice1: 599,
      dprice1: 249,
      duration2: 50,
      oprice2: 899,
      dprice2: 399,
      services: [
        "Exam strategy and preparation",
        "How to balance between school studies and preparation",
        "Study material and resources (mock tests, previous year papers)",
        "How to stay motivated and prevent burnouts during preparations",
        "College application and admission process",
        "What to do in the last few weeks leading up to the exam to maximise performance"
      ]
    },
    {
      id: 3,
      name: "Peehu Sharma",
      education_title: "Education",
      education_value: "MBBS (Jhalawar Medical College)",
      experience_title: "NEET Score",
      experience_value: "617",
      designation: "MBBS Genie",
      photo: require("../../assets/genieImages/peehu_mbbs.jpeg"),
      duration1: 30,
      oprice1: 599,
      dprice1: 249,
      duration2: 50,
      oprice2: 899,
      dprice2: 399,
      services: [
        "Exam strategy and preparation",
        "How to balance between school studies and preparation",
        "Study material and resources (mock tests, previous year papers)",
        "How to stay motivated and prevent burnouts during preparations",
        "College application and admission process",
        "What to do in the last few weeks leading up to the exam to maximise performance"
      ]
    }
  ];
  const navigate = useNavigate();
  return (
    <section id="neet" className="block teams-block">
      <GenieCard title="Talk to NEET Advisor" genieData={genieData} />
    </section>
  );
}

export default NeetGenie;
