import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";

function BookingPage() {
  const location = useLocation();
  const { duration, time, selectedDate, genie } = location.state || {};

  const geniePhoto = genie.photo || '"../assets/genieImages/default_genie.jpeg';

  console.log(String(selectedDate))
  const dateString = String(selectedDate)

  const form = useRef();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    duration, time, dateString,
    genieData: {},
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("option", name, value);
    setFormData({
      ...formData,
      [name]: value,
    });
    console.log(name, value, formData["option"]);
  };
  const navigate = useNavigate();
  function handleDismiss() {
    navigate("/");
  }
  function didTapSubmit(event) {
    event.preventDefault();
    sendMail();
  }

  function sendMail() {
    formData["genieData"] = genie;
    if (formData["option"] == "") {
      formData["option"] = "Rs " + genie.dprice1 + " (" + genie.duration1 + " mins)"
    }
    emailjs
      .send(
        "service_yverrb7",
        "template_ajsp5cd",
        formData,
        "PDIAdXVcMoijbLXha"
      )
      .then((result) => {
        console.log(result.text);
        toast.success(
          <>
            Form submitted successfully!
            <br />
            We will get in touch with you to schedule a call with the genie
          </>,
          {
            onClose: handleDismiss,
          }
        );
      })
      .catch((error) => {
        console.log(error.text);
        alert("Failed to submit form! \n Please try again");
      });
  }

  // let genie = {
  //   id: "genie",
  //   oprice: 1000,
  //   dprice: 500,
  //   name: "Kripali",
  //   photo: require("../assets/genieImages/sajal_mbbs.jpeg"),
  // };

  if (location.state) {
    // genie = location.state.data;
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="block contact-block">
      <div id="bookingdetails">
        <div id="bd">
        <img src={geniePhoto} alt=""  style={{ width: '120px', height: '120px', borderRadius:'20rem', border:'4px solid white', marginRight:'3rem' }}></img>
        <div><p>Duration: {duration}</p>
        <p>Selected Date: {selectedDate ? new Date(selectedDate).toDateString() : "No date selected"}</p>
        <p>Timings: {time}</p></div>
      </div>
      
    </div>
      <Container fluid style={{padding:'100px 50px'}}>
        <div className="title-holder" id="title-holder">
          <h2>Book Now</h2>
          <div className="subtitle">book your favourite genie</div>
        </div>
        <Form className="contact-form" ref={form} onSubmit={didTapSubmit}>
          <Row>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              placeholder="Enter your full name"
              onChange={handleChange}
              required
            />
          </Row>
          <Row>
            <Form.Control
              type="email"
              placeholder="Enter your email address"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Row>
          <Row>
            <Form.Control
              type="tel"
              name="contact"
              value={formData.contact}
              onChange={handleChange}
              placeholder="Enter your contact number/ whatsapp number"
              required
            />
          </Row> 
          <div className="btn-holder" style={{marginTop:'80px'}}>
            <Button type="submit">Book Genie</Button>
          </div>
        </Form>
        <ToastContainer />
      </Container>
    </section>
  );
}

export default BookingPage;

export function goToBookingPage(navigate, genieData) {
  navigate("/bookingpage", { state: { data: genieData } });
}
