import React from "react";
import { useNavigate } from "react-router-dom";
import GenieCard from "../genie_card";

function DomesticItenary() {
  const genieData = [
    {
      id: 1,
      name: "Rahul Seth",
      education_title: "Current Job",
      education_value: "Works in Travel Company",
      experience_title: null,
      experience_value: null,
      photo: require("../../assets/genieImages/default_genie.jpeg"),
      designation: "Domestic Travel and Itenary Advisor",
      duration1: 30,
      oprice1: 199,
      dprice1: 99,
      duration2: null,
      oprice2: null,
      dprice2: null,
      services: [
        "Destination Selection: Offering suggestions on top destinations within the country based on the traveler’s interests, budget, and time frame.",
        "Travel Itinerary Planning: Creating a detailed day-to-day itinerary covering sightseeing, activities, and local experiences.",
        "Accommodation Booking: Providing recommendations for hotels, resorts, or alternative accommodations like homestays or rentals that match the traveler’s preferences.",
        "Transport Arrangements: Advising on domestic flights, trains, buses, or car rentals for efficient travel between destinations.",
        "Activity Recommendations: Curating lists of activities such as city tours, adventure sports, historical site visits, or cultural experiences.",
        "Cost Estimation: Helping travelers plan their trip budget, factoring in accommodation, transport, food, and activities.",
        "Local Cuisine Guide: Suggesting popular local dishes and restaurants for an authentic culinary experience.",
        "Travel Tips and Safety: Offering practical travel tips, including safety precautions, best travel practices, and essential packing lists.",
        "Permit and Documentation Assistance: Providing information on permits or documentation needed for restricted or special areas within the country.",
        "Seasonal Travel Advice: Recommending the best time to visit specific destinations, considering weather conditions and festivals or events.",
      ],
    },
  ];
  const navigate = useNavigate();
  return (
    <section id="domestic" className="block teams-block">
      <GenieCard
        title="Domestic Travel and Itenary Advisor"
        genieData={genieData}
      />
    </section>
  );
}

export default DomesticItenary;
