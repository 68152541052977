import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import AppHeader from "./components/header";
import AppHero from "./components/hero";
import AppAbout from "./components/about";
import AppServices from "./components/services";
import AppTeams from "./components/teams";
import AppContact from "./components/contact";
import AppFooter from "./components/footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Appmain from "./components/main";
import Educationgenie from "./components/education/educationwrapper";
import BookingPage from "./components/bookingPage";
import Appnewbookgenie from "./components/newbookgenie";
import FinanceGenie from "./components/finance/financewrapper";
import HealthGenie from "./components/health/healthwrapper";
import LegalGenie from "./components/legal/legalwrapper";
import BookingDetailsForm from "./components/BookingDetailsForm"
import AppRegister from "./components/register";
import { Helmet } from "react-helmet";
import Eventsgenie from "./components/events_n_wedding/eventwrapper";
import GovtSchemegenie from "./components/govt.schemes/govtschemewrapper";
import Travelgenie from "./components/travel/travelwrapper";
import Fashiongenie from "./components/fashion/fashionwrapper";

function App() {
  return (
    <div className="App">

      <Helmet>  
        <html lang="en" />  
        <title>Genie Tank</title>
        <link rel="icon" type="image/png" href="%PUBLIC_URL%/favicon.ico" sizes="16x16" />
        <meta 
          name="description" 
          content="Your go-to platform for expert advice across education, finance, law, healthcare, and more. Connect with top advisors and get personalized solutions from the comfort of your home." />  
        <meta
          name="keywords"
          content="genie tank, Genie Tank, Genie, Tank, Expert advice, Professional guidance, Finance advice, Legal consultation, Healthcare tips, Personalized solutions, Online advisors, Virtual consultation, Advisory services, Home consultation, Education experts, Business advice, Affordable expert advice, Legal experts, Financial planning, Health advice, Professional advice online, Connect with experts, Expert consultation, Top advisors,advice, advisory hub, Education Mentorship, counselling, counsellor, one on one counselling, JEE counselling, IIT counselling, NEET counselling, JEE Preparation, NEET Coaching, IIT Guidance, Medical Exam Prep, Government Exam Help, Study Abroad Advice, Online Tutoring, Professional Mentors, Academic Support, Career Counseling, Exam Strategies, Topper Tips, Expert Tutors, Student Guidance, Personalized Coaching, Competitive Exam Prep, Higher Education Advice, Scholarship Guidance, Test Preparation Services, advise"
        />
        {/* <meta name="theme-color" content="#E6E6FA" />   */}

        {/* open graph tags */}
        <meta property="og:site_name" content="Genie Tank"/>
        <meta property="og:title" content="GenieTank" />
        <meta property="og:description" content="Your go-to platform for expert advice across education, finance, law, healthcare, and more. Connect with top advisors and get personalized solutions from the comfort of your home." />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
        <meta property="og:URL" content="https://genietank.com/" />
        <meta property="og:type" content="website" />
      </Helmet>

      <header id="header">
        <AppHeader />
      </header>
      <Router>
        <Routes>
          <Route exact path="/register" element={<AppRegister />}></Route>
          <Route exact path="/" element={<Appmain />}></Route>
          <Route exact path="/education" element={<Educationgenie/>}></Route>
          <Route exact path="/finance" element={<FinanceGenie/>}></Route>
          <Route exact path="/health" element={<HealthGenie/>}></Route>
          <Route exact path="/legal" element={<LegalGenie/>}></Route>
          <Route exact path="/govtScheme" element={<GovtSchemegenie/>}></Route>
          <Route exact path="/travel" element={<Travelgenie/>}></Route>
          <Route exact path="/fashion" element={<Fashiongenie/>}></Route>
          <Route exact path="/events" element={<Eventsgenie/>}></Route>
          <Route exact path="/about" element={<AppAbout />}></Route>
          <Route exact path="/services" element={<AppServices />}></Route>
          <Route exact path="/works" element={<AppServices />}></Route>
          <Route exact path="/teams" element={<AppTeams />}></Route>
          <Route exact path="/contact" element={<AppContact />}></Route>
          <Route exact path="/bookingpage" element={<BookingPage />}></Route>
          <Route exact path="/bookgenie" element={<Appnewbookgenie/>}></Route>
          <Route exact path="/booking-details" element={<BookingDetailsForm/>}></Route>
        </Routes>
      </Router>
      <footer id="footer">
        <AppFooter />
      </footer>
    </div>
  );
}

export default App;
