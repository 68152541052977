import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { goToBookingPage } from "../bookingPage";
import { useNavigate } from "react-router-dom";
import GenieCard from "../genie_card";

function CommerceGenie() {
  const genieData = [
    {
      id: 1,
      name: "Surbhi Gupta",
      education_title: "Education",
      education_value: "MSc Quantitative Finance,UCD Michael Smurfit Graduate Business School, Dublin, Ireland",
      experience_title: "Current Job",
      experience_value: "IFRS17 Actuarial Analyst at Fairfax Financial Holdings",
      photo: require("../../assets/genieImages/surbhi_commerce.jpeg"),
      designation: "Commerce Genie",
      duration1: 30,
      oprice1: 599,
      dprice1: 249,
      duration2: 50,
      oprice2: 899,
      dprice2: 399,
      services: ["Study Plan: Share an effective study plan and timetable for systematic preparation.",
      "Mock Tests: Recommend taking regular mock tests to assess preparation levels and improve performance.",
      "Previous Year Papers: Advise on practicing previous year papers to get familiar with the exam pattern and question types.",
      "Time Management in Exams: Provide tips on managing time efficiently during the exam.",
      "Important Resources: Suggest the best books, online courses, and other study materials for preparation.",
      "Stress Management: Share techniques to handle exam stress and stay motivated.",
      "Revision Strategy: Highlight the importance of regular revision and how to plan it effectively."
    ]
    },
    {
      id: 2,
      name: "Mehak Ahuja",
      education_title: "Education",
      education_value: "MSc Quantitative Finance,UCD Michael Smurfit Graduate Business School, Dublin, Ireland",
      experience_title: "Current Job",
      experience_value: "Group Actuarial Analyst at PartnerRe, Dublin",
      photo: require("../../assets/genieImages/mehak_commerce.jpeg"),
      designation: "Commerce Genie",
      duration1: 30,
      oprice1: 599,
      dprice1: 249,
      duration2: 50,
      oprice2: 899,
      dprice2: 399,
      services: ["Study Plan: Share an effective study plan and timetable for systematic preparation.",
      "Mock Tests: Recommend taking regular mock tests to assess preparation levels and improve performance.",
      "Previous Year Papers: Advise on practicing previous year papers to get familiar with the exam pattern and question types.",
      "Time Management in Exams: Provide tips on managing time efficiently during the exam.",
      "Important Resources: Suggest the best books, online courses, and other study materials for preparation.",
      "Stress Management: Share techniques to handle exam stress and stay motivated.",
      "Revision Strategy: Highlight the importance of regular revision and how to plan it effectively."
    ]
    }
  ];
  const navigate = useNavigate();
  return (
    <section id="commerce" className="block teams-block">
      <GenieCard title="Talk to Commerce-Related Advisor" genieData={genieData} />
    </section>
  );
}

export default CommerceGenie;
