import React from "react";
import { useNavigate } from "react-router-dom";
import GenieCard from "../genie_card";

function VisaAdvisor() {
  const genieData = [
    {
      id: 1,
      name: "Rajesh Sharma",
      education_title: "Current Job",
      education_value: "Works in Travel Company",
      experience_title: null,
      experience_value: null,
      photo: require("../../assets/genieImages/default_genie.jpeg"),
      designation: "Visa Advisor",
      duration1: 30,
      oprice1: 199,
      dprice1: 99,
      duration2: null,
      oprice2: null,
      dprice2: null,
      services: [
        "Visa Application Assistance: Help with completing and submitting visa applications.",
        "Document Preparation: Guidance on gathering and organizing necessary documents.",
        "Eligibility Assessment: Evaluation of eligibility based on visa requirements.",
        "Interview Preparation: Coaching and tips for visa interviews.",
        "Application Tracking: Monitoring the status of visa applications and providing updates.",
        "Appeal Support: Assistance with appeals for visa rejections or delays.",
      ],
    },
  ];
  const navigate = useNavigate();
  return (
    <section id="visa" className="block teams-block">
      <GenieCard title="Visa Advisor" genieData={genieData} />
    </section>
  );
}

export default VisaAdvisor;
