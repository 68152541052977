import React from "react";
import { useNavigate } from "react-router-dom";
import GenieCard from "../genie_card";

function ImageNStylingAdvisor() {
  const genieData = [
    {
      id: 1,
      name: "Mansi Sinha",
      education_title: "Fashion",
      education_value: "Styling Advisor",
      experience_title: "Current Job",
      experience_value: "Personal Image and Styling Designer",
      photo: require("../../assets/genieImages/default_genie.jpeg"),
      designation: "Personal Image and Styling Designer",
      duration1: 30,
      oprice1: 599,
      dprice1: 249,
      duration2: 50,
      oprice2: 899,
      dprice2: 399,
      services: [
        "Style Analysis: Evaluating personal style preferences, body type, and lifestyle to create a cohesive and flattering look.",
        "Wardrobe Consultation: Reviewing current wardrobe and suggesting items to keep, donate, or purchase for a more refined look.",
        "Personal Shopping: Offering personalized shopping assistance for clothing, accessories, and beauty products.",
        "Color Analysis: Recommending colors that complement skin tone, hair, and eye color.",
        "Event Styling: Providing outfit recommendations for specific events like job interviews, weddings, or social gatherings.",
        "Hair and Makeup Advice: Suggesting hairstyles and makeup looks that enhance natural features and suit personal style.",
        "Accessorizing Tips: Advising on how to use accessories like belts, bags, and jewelry to elevate an outfit.",
        "Professional Image Development: Helping individuals cultivate a professional appearance aligned with their career goals.",
        "Trend Updates: Keeping clients informed about current fashion trends and advising on how to incorporate them into their personal style.",
        "Confidence Building: Offering guidance on how to dress in a way that boosts self-confidence and self-expression.",
        "Seasonal Wardrobe Planning: Helping clients transition their wardrobe for different seasons while maintaining a consistent style.",
      ],
    },
  ];
  const navigate = useNavigate();
  return (
    <section id="imageNStyling" className="block teams-block">
      <GenieCard title="Image and Styling Advisor" genieData={genieData} />
    </section>
  );
}

export default ImageNStylingAdvisor;
