import React, { useEffect } from "react";
import AnniversaryGenie from "./anniversary";
import BirthdayGenie from "./birthday";
import { useLocation } from "react-router-dom";
import WeddingGenie from "./wedding";
import MusicalEventGenie from "./musical_events";

function Eventsgenie() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const subcat = searchParams.get("subcat");
  useEffect(() => {
    const section = document.querySelector("#" + subcat);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <>
      <WeddingGenie />
      <BirthdayGenie/>
      <AnniversaryGenie/>
      <MusicalEventGenie />
    </>
  );
}

export default Eventsgenie;
