import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { goToBookingPage } from "../bookingPage";
import { useNavigate } from "react-router-dom";
import GenieCard from "../genie_card";

function CareerGenie() {
  const genieData = [
    {
      id: 1,
      name: "Courses and Career Advice after 10th",
      education_title: "Education",
      education_value: "Helps students choose the right subjects, courses, and career opportunities aligned with their interests and strengths.",
      experience_title: null,
      experience_value: null,
      photo: require("../../assets/genieImages/default_genie.jpeg"),
      designation: "Career Genie",
      duration1: 30,
      oprice1: 599,
      dprice1: 249,
      duration2: 60,
      oprice2: 899,
      dprice2: 399,
      services: ["Career Exploration: Help students explore various career options based on their interests, strengths, and academic performance.",
      "Subject Selection: Provide advice on selecting the right subjects for 11th and 12th grades that align with their career goals.",
      "Skill Assessment: Conduct assessments to identify students' skills, aptitudes, and areas of improvement.",
      "Educational Pathways: Explain different educational pathways and the requirements for various careers, including vocational and technical education.",
      "Setting Goals: Assist students in setting short-term and long-term career goals.",
      "Resource Recommendations: Suggest resources such as books, websites, and online courses to help students learn more about their chosen careers.",
      "Extracurricular Activities: Advise on extracurricular activities and volunteer opportunities that can enhance college applications and resumes.",
      "Scholarship Information: Provide information on scholarships and financial aid opportunities.",
      "Parental Guidance: Offer sessions to inform and guide parents about their child's career options and support systems.",
      "Motivation and Confidence Building: Help students build confidence and stay motivated in their academic and career pursuits."
    ]
    },
    {
      id: 2,
      name: "Career and College Advice after 12th",
      education_title: "Education",
      education_value: "Help students choose between diverse higher education options and career paths aligned with their long-term goals.",
      experience_title: null,
      experience_value: null,
      photo: require("../../assets/genieImages/default_genie.jpeg"),
      designation: "Career Genie",
      duration1: 30,
      oprice1: 699,
      dprice1: 349,
      duration2: 60,
      oprice2: 1049,
      dprice2: 549,
      services: [
        "College Applications: Assist students in selecting and applying to colleges and universities that align with their career goals.",
        "Career Guidance: Provide detailed information on various career options and the pathways to achieve them.",
        "Entrance Exam Preparation: Offer strategies and resources for preparing for entrance exams like JEE, NEET, and other relevant tests.",
        "Scholarship and Financial Aid: Guide students in finding and applying for scholarships, grants, and financial aid opportunities.",
        "Resume Building: Help students create and refine their resumes for college applications and job searches.",
        "Interview Preparation: Conduct mock interviews and provide tips for acing college admission and job interviews.",
        "Personal Statements: Assist in writing compelling personal statements and essays for college applications.",
        "Skill Development: Recommend courses and activities to develop skills relevant to the student's chosen career path.",
        "Gap Year Planning: Provide advice on productive ways to spend a gap year if students choose to take one.",
        "Mental Health Support: Offer resources and support to manage stress and maintain mental well-being during this crucial period."
      ]
    },
    {
      id: 3,
      name: "Career advice for graduates and professionals",
      education_title: "Education",
      education_value: "Helps in navigating career transitions, advancing in current roles, or exploring new opportunities, ensuring alignment with personal goals and market demands.",
      experience_title: null,
      experience_value: null,
      photo: require("../../assets/genieImages/default_genie.jpeg"),
      designation: "Career Genie",
      duration1: 30,
      oprice1: 749,
      dprice1: 399,
      duration2: 60,
      oprice2: 1099,
      dprice2: 599,
      services: [
        "Career Transition: Assist in planning and executing a career change, whether within the same industry or into a new field.",
        "Skill Development: Recommend courses, certifications, and training programs to enhance skills and stay competitive.",
        "Resume and LinkedIn Optimization: Help refine resumes and LinkedIn profiles to better reflect skills, experience, and career goals.",
        "Interview Preparation: Provide mock interviews and tips to improve performance in job interviews.",
        "Career Progression: Offer strategies for advancing within a current role or company, including negotiation tactics for promotions and raises.",
        "Networking Strategies: Guide on effective networking to build professional relationships and find new opportunities.",
        "Work-Life Balance: Provide advice on managing work-life balance to prevent burnout and maintain productivity.",
        "Personal Branding: Assist in developing a personal brand that enhances professional reputation and marketability.",
        "Freelancing and Entrepreneurship: Offer guidance on starting a freelance career or launching a business."
    ]    
    }
  ];
  const navigate = useNavigate();
  return (
    <section id="career" className="block teams-block">
      <GenieCard title="Career Genie" genieData={genieData} />
    </section>
  );
}

export default CareerGenie;
