import AppHero from "./hero";
import AppTeams from "./teams";
import AppContact from "./contact";
import Benefits from "./benefits";
import Testimonials from "./testimonials";
function Appmain() {
  return (
    <>
      {/* <header id="header">
        <AppHeader />
      </header> */}
      <main>
        <AppHero />
        {/* <Appnewbookgenie /> */}
        {/* <AppAbout /> */}
        <Benefits/>
        {/* <AppServices /> */}
        {/* <AppWorks /> */}
        <AppTeams />
        <Testimonials/>
        <AppContact />
      </main>
      {/* <footer id="footer">
        <AppFooter />
      </footer> */}
    </>
  );
}

export default Appmain;
