import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { goToBookingPage } from "./bookingPage";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";

const teamsData = [
  {
    id: 1,
    photo: require("../assets/genieImages/puneet_iit.jpeg"),
    name: "Puneet",
    description:
      "Puneet is a 2018 passout from IIT Kanpur. He is currently working at NHPC. He is here to address all your doubts related to JEE preparation, admission etc.",
    education_title: "Education",
    education_value: "IIT Kanpur (Civil)",
    experience_title: "Current Job",
    experience_value: "Engineer at NHPC",
    designation: "IIT Genie",
    duration1: 30,
    oprice1: 599,
    dprice1: 249,
    duration2: 50,
    oprice2: 899,
    dprice2: 399,
    services: ["Study Strategies: Best ways to prepare for JEE Main and Advanced.",
    "Time Management: How to balance school, coaching, and self-study.",
    "Stress Management: Techniques to stay calm and focused.",
    "Previous Year Papers: How to effectively utilise past papers for practice.",
    "Exam Tips: Last-minute preparation and exam day strategies.",
    "Resource Recommendations: Best books and online resources to follow.",
    "Mock Tests: How to analyse and learn from your mock test performances."]
  },
  {
    id: 2,
    photo: require("../assets/genieImages/sparsh_investment.jpeg"),
    name: "Sparsh Vijay",
    description:
    "Sparsh has 4.5 years of experience in the investments line. He advise clients on how to invest their money according to their needs, recommending mutual funds, stocks, and fixed deposits. Additionally, he review the portfolios of clients who have already invested to ensure they are on track.",
    education_title: "Designation",
    education_value: "Stock Market / Fund Manager",
    experience_title: "Experience",
    experience_value: "4.5 years",
    designation: "Investment Genie",
    duration1: 30,
    oprice1: 249,
    dprice1: 99,
    duration2: 60,
    oprice2: 399,
    dprice2: 149,
    services: ["Creating personalised investment plans based on your goals", "Analysing your portfolio", "Providing financial education", "Recommending stocks", "Reviewing IPOs"]
  },
  {
    id: 3,
    photo: require("../assets/genieImages/puneet_mbbs.jpg"),
    name: "Puneet Jindal",
    education_title: "Education",
    description:
      "Puneet is a medical student from Maulana Azad Medical College. He has a neet score of 685. He is here to solve all your queries regarding MBBS/Medical Admissions. He can also address health-related issues.",
    education_value: "MBBS (Maulana Azad Medical College)",
    experience_title: "NEET Score",
    experience_value: "685",
    designation: "MBBS Genie",
    duration1: 30,
    oprice1: 599,
    dprice1: 249,
    duration2: 50,
    oprice2: 899,
    dprice2: 399,
    services: [
      "Exam strategy and preparation",
      "How to balance between school studies and preparation",
      "Study material and resources (mock tests, previous year papers)",
      "How to stay motivated and prevent burnouts during preparations",
      "College application and admission process",
      "What to do in the last few weeks leading up to the exam to maximise performance"
    ]
  },
  {
    id: 4,
    photo: require("../assets/genieImages/rahul_yoga.jpeg"),
    name: "Rahul Yadav ",
    education_title: "Education",
    education_value: "Msc in Yoga Science",
    experience_title: "Experience",
    experience_value: "10 years",
    description:
    "Rahul is our yoga expert who has been practising yoga since 10 years and teaching yoga since 5 years. He is Patanjali University certified. He is here for ensuring proper technique, personalized guidance, and a safe, effective, and enriching yoga asanas and practice.",
    designation: "Yoga Genie",
    duration1: 30,
    oprice1: 199,
    dprice1: 99,
    duration2: null,
    oprice2: null,
    dprice2: null,
    services: [
      "Personalized Yoga Plans: Creating customized yoga routines based on individual goals, needs, and physical conditions.",
      "Yoga Technique Guidance: Providing instruction on proper alignment, breathing techniques, and effective practice methods.",
      "Stress Relief: Advising on yoga practices to manage and reduce stress, including relaxation and mindfulness techniques.",
      "Flexibility and Strength Building: Offering guidance on yoga poses and sequences to improve flexibility, strength, and balance.",
      "Injury Prevention and Recovery: Advising on yoga practices that help prevent injuries and support recovery from physical ailments.",
      "Mental Clarity and Focus: Teaching techniques to enhance mental clarity, concentration, and emotional balance through yoga.",
      "Breathing Exercises: Providing instruction on pranayama (breath control) to enhance overall health and relaxation.",
      "Mindfulness and Meditation: Offering guidance on incorporating mindfulness and meditation practices into daily life.",
      "Yoga for Specific Health Conditions: Tailoring yoga practices to address specific health issues such as chronic pain, hypertension, or anxiety.",
      "Lifestyle and Wellness Integration: Advising on how to integrate yoga principles into daily life for overall wellness and healthy living.",
      "Yoga for Athletes: Providing specialized yoga routines to enhance athletic performance and aid in recovery.",
      "Yoga for Beginners: Offering foundational yoga practices and advice for individuals new to yoga.",
      "Advanced Practice Techniques: Guiding advanced practitioners in exploring deeper aspects of yoga practice, including advanced poses and techniques.",
      "Holistic Wellness: Advising on the integration of yoga with other wellness practices such as nutrition, meditation, and healthy living."
  ]
  },
];

function AppTeams() {
  const rating = require("../assets/images/rating.png")
  const navigate = useNavigate();
  return (
    <section id="teams" className="block teams-block">
      <Container fluid>
        <div className="title-holder" id="title-holder">
          <h2>Star Genies of the Month <b style={{ color:'#f53333'}}>August</b></h2>
          <div className="subtitle">some of our experts</div>
        </div>
        <Row>
          {teamsData.map((genie) => {
            return (
              <Col sm={3} key={genie.id}>
                <div  className="genie-card">
                <div className="image" id="top-genie-image">
                  <Image src={genie.photo} />
                  <div className="overlay">
                    <div className="socials">
                      <center>
                        <Button
                          variant="light"
                          id="social-button"
                          onClick={() => goToBookingPage(navigate, genie)}
                        >
                          Book Now
                        </Button>
                      </center>
                    </div>
                  </div>
                </div>
                <div className="content">
                <Image src={rating} style={{width:'150px', marginTop:'-30px', marginBottom:'-20px'}}/>
                  <h3>{genie.name}</h3>
                  <span className="designation">{genie.designation}</span>
                  <p style={{textAlign:'justify'}}>{genie.description}</p>
                </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
}

export default AppTeams;
