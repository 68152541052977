import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import img from "../assets/images/logo.png";
import Dropdown from "react-bootstrap/Dropdown";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./header.css";

const AppHeader = () => {
  const ed = require("../assets/images/Education.png");
  const le = require("../assets/images/legal.png");
  const he = require("../assets/images/health.png");
  const fi = require("../assets/images/finance.png");
  const fa = require("../assets/images/fashion.png");
  const ta = require("../assets/images/travel.png");
  const go = require("../assets/images/govt.png");
  const ev = require("../assets/images/event.png");
  const en = require("../assets/images/eng.png");

  const educationGenieCategories = [
    {
      id: "iit",
      name: "Talk to an IITian",
    },
    {
      id: "neet",
      name: "Talk to NEET Advisor",
    },
    {
      id: "commerce",
      name: "Talk to Commerce Advisor",
    },
    {
      id: "abroad",
      name: "Talk to International Students Advisor",
    },
    {
      id: "career",
      name: "Career Advisor",
    },
    {
      id: "govtExam",
      name: "Govt. Exams Advisor",
    },
  ];
  const legalGenieCategories = [
    {
      id: "civil",
      name: "Civil Lawyer",
    },
    {
      id: "corporate",
      name: "Corporate Lawyer",
    },
    {
      id: "crime",
      name: "Criminal Lawyer",
    },
  ];
  const healthGenieCategories = [
    {
      id: "yoga",
      name: "Yoga Advisor",
    },
    {
      id: "gynae",
      name: "Teenage Health Advisor",
    },
    {
      id: "pyschologist",
      name: "Psychologist",
    },
    {
      id: "gym",
      name: "Gym Advisor",
    },
    {
      id: "nutritionist",
      name: "Diet & Nutritionist Advisor",
    },
  ];
  const financeGenieCategories = [
    {
      id: "ca",
      name: "CA",
    },
    {
      id: "investment",
      name: "Investments Advisor",
    },
  ];
  const govtSchemeGenieCategories = [
    {
      id: "central",
      name: "Central Level Govt. Schemes Advisor",
    },
    {
      id: "state",
      name: "State Level Govt. Schemes Advisor",
    },
  ];
  const fashionGenieCategories = [
    {
      id: "brideGroomApparel",
      name: "Bride and Groom Apparel Advisor",
    },
    {
      id: "imageNStyling",
      name: "Image and Styling Advisor",
    },
  ];
  const travelGenieCategories = [
    {
      id: "visa",
      name: "Visa Advisor",
    },
    // },
    // {
    //   id: "workVisa",
    //   name: "Work Visa Advisor",
    // },
    // {
    //   id: "travelVisa",
    //   name: "Travel Visa Advisor",
    // },
    {
      id: "international",
      name: "International Itenary Advisor",
    },
    {
      id: "domestic",
      name: "Domestic Itenary Advisor",
    },
  ];
  const eventsGenieCategories = [
    {
      id: "wedding",
      name: "Wedding Events Advisor",
    },
    {
      id: "birthday",
      name: "Birthday Events Advisor",
    },
    {
      id: "anniversary",
      name: "Anniversary Events Advisor",
    },
    {
      id: "musical_event",
      name: "Musical Events Advisor",
    },
  ];

  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container
          style={{ borderBottom: "solid #999999a4 0.5px", marginBottom: "4px" }}
        >
          <Navbar.Brand href="/">
            <img alt="" src={img} width="100" height="100" />
            <span></span>
            Genie Tank
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/services">Services</Nav.Link>
              <Nav.Link href="/about">About Us</Nav.Link>
              <Nav.Link href="/teams">Top Genies</Nav.Link>
              <Nav.Link href="/contact">Contact</Nav.Link>
              <Nav.Link
                href="/register"
                style={{ fontWeight: "bolder", color: "#f64b4b" }}
              >
                <u>Register As Genie</u>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Navbar className="bg-body-tertiary" style={{ paddingBottom: "10px" }}>
        <Container className="cont">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <div id="NavDropdowns">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <img src={ed} alt="" width={"50px"} />
                    Education
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {educationGenieCategories.map((genieCat) => {
                      const url = "/education?subcat=" + genieCat.id;
                      return (
                        <Dropdown.Item href={url}>
                          {genieCat.name}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>

                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <img src={le} alt="" width={"50px"} />
                    Legal
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {legalGenieCategories.map((genieCat) => {
                      const url = "/legal?subcat=" + genieCat.id;
                      return (
                        <Dropdown.Item href={url}>
                          {genieCat.name}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <img src={he} alt="" width={"50px"} />
                    Health & Nutrition
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {healthGenieCategories.map((genieCat) => {
                      const url = "/health?subcat=" + genieCat.id;
                      return (
                        <Dropdown.Item href={url}>
                          {genieCat.name}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <img src={fi} alt="" width={"50px"} />
                    Finance
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {financeGenieCategories.map((genieCat) => {
                      const url = "/finance?subcat=" + genieCat.id;
                      return (
                        <Dropdown.Item href={url}>
                          {genieCat.name}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <img src={fa} alt="" width={"50px"} />
                    Fashion & Lifestyle
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {fashionGenieCategories.map((genieCat) => {
                      const url = "/fashion?subcat=" + genieCat.id;
                      return (
                        <Dropdown.Item href={url}>
                          {genieCat.name}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <img src={ta} alt="" width={"50px"} />
                    Travel
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {travelGenieCategories.map((genieCat) => {
                      const url = "/travel?subcat=" + genieCat.id;
                      return (
                        <Dropdown.Item href={url}>
                          {genieCat.name}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <img src={go} alt="" width={"50px"} />
                    Govt. Schemes
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {govtSchemeGenieCategories.map((genieCat) => {
                      const url = "/govtScheme?subcat=" + genieCat.id;
                      return (
                        <Dropdown.Item href={url}>
                          {genieCat.name}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <img src={ev} alt="" width={"50px"} />
                    Events & Weddings
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {eventsGenieCategories.map((genieCat) => {
                      const url = "/events?subcat=" + genieCat.id;
                      return (
                        <Dropdown.Item href={url}>
                          {genieCat.name}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
                {/* <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                <img src={en} alt=""  width={'50px'}/>
                 Engineering
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {financeGenieCategories.map((genieCat) => {
                    const url = "/finance?subcat=" + genieCat.id;
                    return (
                      <Dropdown.Item href={url}>{genieCat.name}</Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown> */}
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>

        <Navbar id="MobNav">
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav>
              <div className="mobnav-container">
                <div className="mobnav-div">
                  <NavDropdown title="Education">
                    {educationGenieCategories.map((genieCat) => {
                      const url = "/education?subcat=" + genieCat.id;
                      return (
                        <NavDropdown.Item id="NavBtn" href={url}>
                          {genieCat.name}
                        </NavDropdown.Item>
                      );
                    })}
                  </NavDropdown>
                  <NavDropdown title="Legal">
                    {legalGenieCategories.map((genieCat) => {
                      const url = "/legal?subcat=" + genieCat.id;
                      return (
                        <NavDropdown.Item href={url}>
                          {genieCat.name}
                        </NavDropdown.Item>
                      );
                    })}
                  </NavDropdown>
                  <NavDropdown title="Health">
                    {healthGenieCategories.map((genieCat) => {
                      const url = "/health?subcat=" + genieCat.id;
                      return (
                        <NavDropdown.Item href={url}>
                          {genieCat.name}
                        </NavDropdown.Item>
                      );
                    })}
                  </NavDropdown>
                  <NavDropdown title="Finance" align="end">
                    {financeGenieCategories.map((genieCat) => {
                      const url = "/finance?subcat=" + genieCat.id;
                      return (
                        <NavDropdown.Item href={url}>
                          {genieCat.name}
                        </NavDropdown.Item>
                      );
                    })}
                  </NavDropdown>
                  <NavDropdown title="Fashion" align="end">
                    {fashionGenieCategories.map((genieCat) => {
                      const url = "/fashion?subcat=" + genieCat.id;
                      return (
                        <NavDropdown.Item href={url}>
                          {genieCat.name}
                        </NavDropdown.Item>
                      );
                    })}
                  </NavDropdown>
                </div>
                <div className="mobnav-div">
                  <NavDropdown title="Travel">
                    {travelGenieCategories.map((genieCat) => {
                      const url = "/travel?subcat=" + genieCat.id;
                      return (
                        <NavDropdown.Item href={url}>
                          {genieCat.name}
                        </NavDropdown.Item>
                      );
                    })}
                  </NavDropdown>
                  <NavDropdown title="Govt. Schemes">
                    {govtSchemeGenieCategories.map((genieCat) => {
                      const url = "/govtScheme?subcat=" + genieCat.id;
                      return (
                        <NavDropdown.Item href={url}>
                          {genieCat.name}
                        </NavDropdown.Item>
                      );
                    })}
                  </NavDropdown>
                  <NavDropdown title="Events & Weddings" align="end">
                    {eventsGenieCategories.map((genieCat) => {
                      const url = "/events?subcat=" + genieCat.id;
                      return (
                        <NavDropdown.Item href={url}>
                          {genieCat.name}
                        </NavDropdown.Item>
                      );
                    })}
                  </NavDropdown>
                  {/* <NavDropdown title="Engineering" align="end">
              {financeGenieCategories.map((genieCat) => {
                const url = "/finance?subcat=" + genieCat.id;
                return (
                  <NavDropdown.Item href={url}>
                    {genieCat.name}
                  </NavDropdown.Item>
                );
              })}
              </NavDropdown> */}
                </div>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Navbar>
    </>
  );
};

export default AppHeader;
