import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import CentralGovtSchemeGenie from "./central";
import StateGovtSchemeGenie from "./state";

function GovtSchemegenie() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const subcat = searchParams.get("subcat");
  useEffect(() => {
    const section = document.querySelector("#" + subcat);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <>
      <CentralGovtSchemeGenie />
      <StateGovtSchemeGenie/>
    </>
  );
}

export default GovtSchemegenie;
