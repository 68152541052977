import React from "react";
import { Col, Row } from "react-bootstrap";
import Image from 'react-bootstrap/Image';

function ComingSoon() {
  return (
    <Row>
      <Col sm={2}>
        <div className="image">
            <Image src = {require('../assets/images/comingsoon.png')}/>
        </div>
      </Col>
      <Col>
        {/* <div className="title-holder" id="title-holder"> */}
          <h3 className="h3">Genies Coming Soon to Help You</h3>
        {/* </div> */}
      </Col>
    </Row>
  );
}

export default ComingSoon;
