import React from "react";
import { useNavigate } from "react-router-dom";
import GenieCard from "../genie_card";

function WeddingGenie() {
  const genieData = [
    {
      id: 1,
      name: "Jhanvi Singh",
      education_title: "Events",
      education_value: "Wedding Events Planning Advisor",
      experience_title: "Current Job",
      experience_value: "Event Manager",
      photo: require("../../assets/genieImages/default_genie.jpeg"),
      designation: "Wedding Events Planning Advisor",
      duration1: 30,
      oprice1: 599,
      dprice1: 249,
      duration2: 50,
      oprice2: 899,
      dprice2: 399,
      services: [
        "Theme and Concept Development: Assisting in creating a cohesive theme that reflects the couple’s personality and preferences.",
        "Budget Planning and Management: Offering advice on allocating the wedding budget effectively for venues, vendors, and services.",
        "Venue Selection: Recommending wedding venues that match the desired theme, location, and capacity.",
        "Vendor Management: Coordinating with caterers, photographers, florists, and entertainment providers.",
        "Guest List Management: Advising on guest invitations, RSVPs, seating arrangements, and travel logistics.",
        "Wedding Timeline Creation: Developing a detailed schedule for the wedding day, including the ceremony, reception, and key moments.",
        "Decor and Styling: Helping with design and styling ideas, including floral arrangements, table settings, and overall ambiance.",
        "Entertainment Planning: Offering guidance on selecting wedding entertainment like bands, DJs, and other performances.",
        "Legal and Documentation Assistance: Advising on necessary legal steps and documentation for the wedding.",
        "Day-Of Coordination: Ensuring smooth execution of the wedding day through on-site coordination and problem-solving.",
        "Personalized Touches: Incorporating personal elements like custom vows, cultural traditions, or memory tributes into the wedding.",
      ],
    },
  ];
  const navigate = useNavigate();
  return (
    <section id="wedding" className="block teams-block">
      <GenieCard title="Wedding Event Advisor" genieData={genieData} />
    </section>
  );
}

export default WeddingGenie;
