import React from "react";
import { useNavigate } from "react-router-dom";
import GenieCard from "../genie_card";

function InternationalItenary() {
  const genieData = [
    {
      id: 1,
      name: "Manav Seth",
      education_title: "Current Job",
      education_value: "Works in Travel Company",
      experience_title: null,
      experience_value: null,
      photo: require("../../assets/genieImages/default_genie.jpeg"),
      designation: "International Travel and Itenary Advisor",
      duration1: 30,
      oprice1: 199,
      dprice1: 99,
      duration2: null,
      oprice2: null,
      dprice2: null,
      services: [
        "Itinerary Planning: Customized travel itineraries based on client preferences and needs.",
        "Destination Advice: Recommendations on travel destinations and attractions.",
        "Booking Assistance: Help with booking flights, accommodations, and other travel arrangements.",
        "Travel Safety Tips: Guidance on staying safe and secure while traveling.",
        "Visa and Entry Requirements: Information on visa requirements and entry regulations for different countries.",
        "Local Experience Recommendations: Suggestions for local experiences, dining, and activities.",
        "Travel Insurance: Advice on obtaining appropriate travel insurance coverage.",
      ],
    },
  ];
  const navigate = useNavigate();
  return (
    <section id="international" className="block teams-block">
      <GenieCard
        title="International Travel and Itenary Advisor"
        genieData={genieData}
      />
    </section>
  );
}

export default InternationalItenary;
