function Benefits() {
  const ca = require("../assets/images/convinient_access.png")
  const cr = require("../assets/images/cheap_rates.png")
  const se = require("../assets/images/24x7_service.png")
  const em = require("../assets/images/expert matching.png")
    return (
      <section className="GT-benefits-container title-holder">
        <h2 className="benefits-p">Why Choose <b style={{color:'#f53333'}}>GenieTank</b>?</h2>
        <div className="GT-benefits">
            <div className="benefits-item"><div><img src={ca} alt="Convinient Access" style={{width:'190px', height:'180px'}}/></div>Convenient Access</div>
            <div className="benefits-item"><div><img src={cr} alt="Cheap Rates" style={{width:'200px', height:'180px'}}/></div>Cheap Rates</div>
            <div className="benefits-item"><div><img src={se} alt="Anytime, Anywhere" style={{width:'180px', height:'180px'}}/></div>Anytime, Anywhere (24 x 7)</div>
            <div className="benefits-item"><div><img src={em} alt="Expert Matching" style={{width:'200px', height:'180px'}}/></div>Expert Matching</div>
        </div>
      </section>
    );
  }
  
  export default Benefits;