import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import { goToBookingPage } from "../bookingPage";
import GenieCard from "../genie_card";

function CrimeGenie() {
  const genieData = [];
  const navigate = useNavigate();
  return (
    <section id="crime" className="block teams-block">
      <GenieCard title="Criminal Lawyer Genie" genieData={genieData} />
    </section>
  );
}

export default CrimeGenie;
