import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { goToBookingPage } from "../bookingPage";
import { useNavigate } from "react-router-dom";
import GenieCard from "../genie_card";

function GymGenie() {
  const genieData = [
  ];
  const navigate = useNavigate();
  return (
    <section id="gym" className="block teams-block">
     <GenieCard title="Gym Genie" genieData={genieData}/>
    </section>
  );
}

export default GymGenie;
