import React from "react";
import { useNavigate } from "react-router-dom";
import GenieCard from "../genie_card";

function StateGovtSchemeGenie() {
  const genieData = [
    {
      id: 1,
      name: "Atul Jain",
      education_title: "State Govt. Scheme Advisor",
      education_value: "State Govt. Scheme Advisor",
      experience_title: null,
      experience_value: null,
      photo: require("../../assets/genieImages/default_genie.jpeg"),
      designation: "State Govt. Scheme Advisor",
      duration1: 30,
      oprice1: 599,
      dprice1: 249,
      duration2: 50,
      oprice2: 899,
      dprice2: 399,
      services: [
        "Scheme Information: Offering detailed knowledge about various state government schemes across sectors like education, housing, employment, and agriculture.",
        "Eligibility Verification: Assisting clients in determining eligibility for state government benefits, subsidies, and financial aid.",
        "Application Guidance: Providing step-by-step assistance on how to apply for state government schemes, ensuring accurate documentation and timely submission.",
        "Subsidy and Grant Details: Advising on available state-level grants, subsidies, and incentive programs.",
        "Benefit Estimation: Helping clients calculate potential benefits or financial aid under relevant state schemes.",
        "Sector-Specific Support: Offering specialized advice on schemes related to sectors like healthcare, rural development, or women empowerment.",
        "Compliance and Legal Assistance: Ensuring all legal and compliance requirements are met for scheme application and participation.",
        "Updates on New Schemes: Providing updates on newly introduced state schemes or changes to existing ones.",
        "Dispute Resolution: Assisting in resolving issues related to scheme benefits, delays, or denials.",
        "Localized Guidance: Offering specific insights on how regional policies and schemes impact individuals or businesses within the state.",
      ],
    },
  ];
  const navigate = useNavigate();
  return (
    <section id="state" className="block teams-block">
      <GenieCard title="State Govt. Schemes Advisor" genieData={genieData} />
    </section>
  );
}

export default StateGovtSchemeGenie;
