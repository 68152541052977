import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';

function AppFooter() {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
      window.addEventListener("scroll", () => {
          if (window.scrollY > 400) {
              setShowTopBtn(true);
          } else {
              setShowTopBtn(false);
          }
      });
  }, []);

  function goTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const logo = require('../assets/images/logo.png')

  return (
    <Container fluid>
      <div>
        {/* <div className="footer-content">
        <div className="gt-logo"><img alt="" src={logo} width="80" height="100" />
          <h2 style={{color:'#f64b4b'}}>Genie Tank</h2>
        </div>
          <ul>
            <li><a href="https://www.facebook.com">About us</a></li>
            <li><a href="https://www.twitter.com">Privacy Policy</a></li>
            <li><a href="https://www.linkedin.com">FAQs</a></li>
            <li><a href="https://www.linkedin.com">Why us?</a></li>
            <li><a href="https://www.linkedin.com">Terms & Conditions</a></li>

          </ul>
        </div> */}
      <div className="socials">
        <address>
        <ul>
          <li><a href="https://www.facebook.com"><i className="fab fa-facebook-f"></i></a></li>
          <li><a href="https://www.twitter.com"><i className="fab fa-twitter"></i></a></li>
          <li><a href="https://www.linkedin.com"><i className="fab fa-linkedin-in"></i></a></li>
          <li><a href="mailto:genietank5@gmail.com" rel="noreferrer" target="_blank"><i className="fas fa-envelope"/></a></li>
          <li><a  href="tel:+91-8368667682"><i className="fas fa-phone"/></a></li>
        </ul>
        </address>
      </div>
      </div>
      <div className="copyright">&copy; 2024 GenieTank.com by Munk Life Communications Pvt. Ltd. </div>
      {
        showTopBtn && (
          <div className="go-top" onClick={goTop}></div>
        )
      }
    </Container>
  )
}

export default AppFooter;