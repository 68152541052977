import React, { useEffect } from "react";
import CareerGenie from "./career";
import IITGenie from "./iit";
import { useLocation } from "react-router-dom";
import GynaeGenie from "../health/gynae";
import NeetGenie from "./neet";
import CommerceGenie from "./commerce";
import AbroadGenie from "./abroad";
import GovtExamGenie from "./govtExam";

function Educationgenie() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const subcat = searchParams.get("subcat");
  useEffect(() => {
    const section = document.querySelector("#" + subcat);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <>
      <IITGenie />
      <NeetGenie/>
      <CommerceGenie/>
      <AbroadGenie/>
      <CareerGenie />
      <GovtExamGenie />
    </>
  );
}

export default Educationgenie;
