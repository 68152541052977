import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import { goToBookingPage } from "../bookingPage";
import GenieCard from "../genie_card";

function GynaeGenie() {
  const genieData = [
    {
      id: 1,
      name: "Sajal Jain",
      education_title: "Education",
      education_value: "MBBS (Jhalwar Medical College)",
      experience_title: null,
      experience_value: "Engineer at NHPC",
      photo: require("../../assets/genieImages/sajal_mbbs.jpeg"),
      designation: "Health Genie",
      duration1: 30,
      oprice1: 699,
      dprice1: 349,
      duration2: null,
      oprice2: null,
      dprice2: null,
      services: [
        "Explaining the physical and emotional changes associated with puberty.",
        "Providing information on the normal stages of development.",
        "Offering guidance on changes such as growth spurts, body hair, and changes in body shape.",
        "Addressing concerns about acne, menstruation, and other bodily changes.",
        "Advising on maintaining a positive body image and self-esteem.",
        "Discussing the impact of media and societal pressures on body image.",
        "Providing advice on balanced diets and nutrition to support healthy growth and development.",
        "Addressing misconceptions about diets and body weight.",
        "Recommending appropriate types and amounts of physical activity for maintaining health and fitness.",
        "Advising on sports and exercise that are beneficial during adolescence.",
        "Offering support for emotional and psychological changes, including mood swings and stress.",
        "Providing resources or referrals for mental health support if needed.",
        "Educating on sexual health, including safe practices, consent, and respect in relationships.",
        "Addressing questions about sexual development and reproductive health.",
        "Advising on proper hygiene practices to manage changes like increased sweating and acne.",
        "Providing tips for maintaining cleanliness and self-care.",
        "Helping to identify when to seek medical advice for unusual or concerning symptoms.",
        "Assisting with understanding common health issues and when to consult a healthcare provider.",
        "Encouraging open discussions with parents or guardians about changes and concerns.",
        "Offering strategies for effective communication about health topics.",
        "Providing techniques for managing stress and anxiety related to body changes and adolescence.",
        "Advising on relaxation and coping strategies.",
        "Explaining typical growth patterns and variations in development.",
        "Providing reassurance about the variability in how individuals grow and develop."
    ]
    },
    {
      id: 2,
      name: "Peehu Sharma",
      education_title: "Education",
      education_value: "MBBS (Jhalwar Medical College)",
      experience_title: null,
      experience_value: "Engineer at NHPC",
      photo: require("../../assets/genieImages/peehu_mbbs.jpeg"),
      designation: "Health Genie",
      duration1: 30,
      oprice1: 699,
      dprice1: 349,
      duration2: null,
      oprice2: null,
      dprice2: null,
      services: [
        "Explaining the physical and emotional changes associated with puberty.",
        "Providing information on the normal stages of development.",
        "Offering guidance on changes such as growth spurts, body hair, and changes in body shape.",
        "Addressing concerns about acne, menstruation, and other bodily changes.",
        "Advising on maintaining a positive body image and self-esteem.",
        "Discussing the impact of media and societal pressures on body image.",
        "Providing advice on balanced diets and nutrition to support healthy growth and development.",
        "Addressing misconceptions about diets and body weight.",
        "Recommending appropriate types and amounts of physical activity for maintaining health and fitness.",
        "Advising on sports and exercise that are beneficial during adolescence.",
        "Offering support for emotional and psychological changes, including mood swings and stress.",
        "Providing resources or referrals for mental health support if needed.",
        "Educating on sexual health, including safe practices, consent, and respect in relationships.",
        "Addressing questions about sexual development and reproductive health.",
        "Advising on proper hygiene practices to manage changes like increased sweating and acne.",
        "Providing tips for maintaining cleanliness and self-care.",
        "Helping to identify when to seek medical advice for unusual or concerning symptoms.",
        "Assisting with understanding common health issues and when to consult a healthcare provider.",
        "Encouraging open discussions with parents or guardians about changes and concerns.",
        "Offering strategies for effective communication about health topics.",
        "Providing techniques for managing stress and anxiety related to body changes and adolescence.",
        "Advising on relaxation and coping strategies.",
        "Explaining typical growth patterns and variations in development.",
        "Providing reassurance about the variability in how individuals grow and develop."
    ]
    
    },
    {
      id: 3,
      name: "Puneet Jindal",
      education_title: "Education",
      education_value: "MBBS (Maulana Azad Medical College)",
      experience_title: null,
      experience_value: null,
      photo: require("../../assets/genieImages/puneet_mbbs.jpg"),
      designation: "Health Genie",
      duration1: 30,
      oprice1: 699,
      dprice1: 349,
      duration2: null,
      oprice2: null,
      dprice2: null,
      services: [
        "Explaining the physical and emotional changes associated with puberty.",
        "Providing information on the normal stages of development.",
        "Offering guidance on changes such as growth spurts, body hair, and changes in body shape.",
        "Addressing concerns about acne, menstruation, and other bodily changes.",
        "Advising on maintaining a positive body image and self-esteem.",
        "Discussing the impact of media and societal pressures on body image.",
        "Providing advice on balanced diets and nutrition to support healthy growth and development.",
        "Addressing misconceptions about diets and body weight.",
        "Recommending appropriate types and amounts of physical activity for maintaining health and fitness.",
        "Advising on sports and exercise that are beneficial during adolescence.",
        "Offering support for emotional and psychological changes, including mood swings and stress.",
        "Providing resources or referrals for mental health support if needed.",
        "Educating on sexual health, including safe practices, consent, and respect in relationships.",
        "Addressing questions about sexual development and reproductive health.",
        "Advising on proper hygiene practices to manage changes like increased sweating and acne.",
        "Providing tips for maintaining cleanliness and self-care.",
        "Helping to identify when to seek medical advice for unusual or concerning symptoms.",
        "Assisting with understanding common health issues and when to consult a healthcare provider.",
        "Encouraging open discussions with parents or guardians about changes and concerns.",
        "Offering strategies for effective communication about health topics.",
        "Providing techniques for managing stress and anxiety related to body changes and adolescence.",
        "Advising on relaxation and coping strategies.",
        "Explaining typical growth patterns and variations in development.",
        "Providing reassurance about the variability in how individuals grow and develop."
    ]
    }
  ];
  const navigate = useNavigate();
  return (
    <section id="gynae" className="block teams-block">
      <GenieCard title = "Teenage Health Advisor" genieData={genieData}/>
    </section>
  );
}

export default GynaeGenie;
