import Carousel from "react-bootstrap/Carousel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

var heroData = [
  {
    id: 1,
    image: require("../assets/images/education-slide.jpg"),
    title: "Education",
    description:
      "Educational genies serves as a compass guiding you through academic choices, career pathways, and personal development.",
    link: "/education",
  },
  {
    id: 2,
    image: require("../assets/images/health-slide.png"),
    title: "Health",
    description:
      "Health genies play a crucial role in promoting well-being, enriched quality of life, improved productivity and enhanced community health outcomes.",
    link: "/health",
  },
  {
    id: 3,
    image: require("../assets/images/law_slide.png"),
    title: "Legal",
    description:
      "Legal genies help you to simplify the complexities of the legal system, ensure compliance with laws and regulations, expertise that safeguard rights, protect interests and minimize liabilities.",
    link: "/legal",
  },
  {
    id: 4,
    image: require("../assets/images/finance_slide.png"),
    title: "Finance",
    description:
      "By leveraging the knowledge and insights of financial genies, you can make informed decisions, mitigate risks, maximize returns, and build a secure financial future.",
    link: "/finance",
  },
  {
    id: 5,
    image: require("../assets/images/wedding.jpg"),
    title: "Events & Weddings",
    description: "",
    link: "/events",
  },
  {
    id: 6,
    image: require("../assets/images/publicdomainservice.jpg"),
    title: "Govt. Schemes",
    description: "",
    link: "/govtScheme",
  },
  {
    id: 7,
    image: require("../assets/images/travel.jpg"),
    title: "",
    description: "",
    link: "/travel",
  },
];

function AppHero() {
  return (
    <section id="home" className="hero-block">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: "75",
          alignItems: "center",
        }}
      >
        <p className="hero-block-p">
          <b style={{ fontFamily: "cursive", color: "#f53333" }}>YOUR</b>{" "}
          JOURNEY <b style={{ fontFamily: "cursive", color: "#f53333" }}>OUR</b>{" "}
          GUIDANCE
        </p>
        <p className="hero-content">
          At Genie Tank, we believe that everyone deserves access to expert
          advice tailored to their unique needs. Whether you're navigating
          education, career, or personal challenges, our platform connects you
          with the best advisors, ensuring you receive personalized guidance
          every step of the way. Your success is our priority, and we are here
          to empower your decisions and unlock your potential.
        </p>
      </div>

      <Carousel className="domain-carousel">
        {heroData.map((hero) => {
          return (
            <Carousel.Item key={hero.id}>
              <a href={hero.link}>
                <img
                  className="w-100 car-img"
                  src={hero.image}
                  alt={"slide " + hero.id}
                />
                <Row>
                  <Col sm={2}>
                    <Carousel.Caption>
                      <h1 style={{ textShadow: "6px 6px 6px #000000" }}>
                        {hero.title}
                      </h1>
                      {/* <p>{hero.description}</p> */}
                    </Carousel.Caption>
                  </Col>
                </Row>
              </a>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </section>
  );
}

export default AppHero;
