import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { goToBookingPage } from "../bookingPage";
import { useNavigate } from "react-router-dom";
import GenieCard from "../genie_card";
function NutritionGenie() {
  const genieData = [
    
  ];
  const navigate = useNavigate();
  return (
    <section id="nutritionist" className="block teams-block">
      <GenieCard title="Diet & Nutritionist Genie" genieData={genieData} />
    </section>
  );
}

export default NutritionGenie;
