import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { goToBookingPage } from "../bookingPage";
import { useNavigate } from "react-router-dom";
import GenieCard from "../genie_card";

function PsychologistGenie() {
  const genieData = [
    {
      id: 1,
      name: "Dimple Shewakramani",
      education_title: "Education",
      education_value: "MSc. in Clinical Psychology",
      experience_title: "Practising at: ",
      experience_value: "Gautam Hospital, Jaipur and Bhargava Hospital, Jaipur",
      photo: require("../../assets/genieImages/dimple_pyschology.png"),
      designation: "Psychologist Genie",
      duration1: 40,
      oprice1: 999,
      dprice1: 499,
      duration2: 60,
      oprice2: 1199,
      dprice2: 699,
      services: [
        "Mental Health Assessment: Evaluating psychological conditions and providing diagnoses.",
        "Stress Management: Advising on techniques to manage and reduce stress.",
        "Therapeutic Interventions: Providing individual and group therapy using various modalities.",
        "Emotional Support: Offering support during difficult life events and transitions.",
        "Relationship Counseling: Improving interpersonal relationships and communication skills.",
        "Career Counseling: Assisting with career decisions and job-related stress.",
        "Self-Improvement: Guiding personal development and goal-setting.",
        "Crisis Intervention: Providing immediate support during psychological crises.",
        "Mindfulness and Relaxation: Teaching practices to enhance self-awareness and reduce anxiety.",
        "Behavioral Modification: Helping clients change unhelpful behaviors and build healthier habits.",
        "Family Therapy: Addressing family dynamics and improving relationships.",
        "Trauma Recovery: Supporting recovery from traumatic experiences.",
        "Support for Mental Health Disorders: Managing chronic mental health conditions and coordinating with other providers.",
        "Health and Lifestyle Changes: Advising on how lifestyle factors impact mental health.",
        "Parenting Support: Providing strategies and support for effective parenting."
    ]
    
    },
  ];
  const navigate = useNavigate();
  return (
    <section id="pyschologist" className="block teams-block">
     <GenieCard title = "Psychologist Genie" genieData={genieData}/>
    </section>
  );
}

export default PsychologistGenie;
